<app-window-title title="Select which documents to share"
                  subtitle
                  [closable]="true"></app-window-title>
<app-client-matter-info [matterData]="matterData"></app-client-matter-info>
<div fxLayout="row">
  <mat-selection-list #list
                      class="mat-list-container"
                      fxFlex="50">
    <div mat-subheader>
      Clio Matter documents not shared to NuageDx
    </div>
    <div class="mat-list-items-container">
      <ng-container *ngFor="let file of sourceDocuments">
        <mat-list-option *ngIf="!file.existing"
                         [value]="file.id">
          {{file.name}}
        </mat-list-option>
      </ng-container>
    </div>
  </mat-selection-list>
  <mat-list fxFlex="50"
            role="list">
    <div mat-subheader>
      NuageDx Client/Matter documents shared from Clio
    </div>
    <div class="mat-list-items-container">
      <mat-list-item *ngFor="let file of localDocuments"
                     class="item-exists"
                     role="listitem">
        {{file.fileName}}
      </mat-list-item>
    </div>
    <mat-list-item *ngIf="localDocuments?.length === 0"
                   class="item-exists"
                   role="listitem">
      No documents shared yet.
    </mat-list-item>
  </mat-list>
</div>
<div class="buttons-row"
     fxLayout="row"
     fxLayoutAlign="space-evenly center">
  <button color="warn"
          mat-flat-button
          (click)="handleImportSelected()">
    <mat-icon>
      done
    </mat-icon>
    Share Selected
  </button>
  <button color="warn"
          mat-flat-button
          (click)="handleImportAll()">
    <mat-icon>
      done_all
    </mat-icon>
    Share All
  </button>
  <button color="warn"
          mat-flat-button
          (click)="continueWithoutAction()">
    <mat-icon>
      block
    </mat-icon>
    Continue without sharing
  </button>
</div>
