import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  getOpenDialogs() {
    return this.dialog.openDialogs;
  }

  open(component: any, data: any) {
    if (this.dialog.openDialogs.map(dialog => dialog.id).includes(data.id))
      data.id = data.id + Math.floor(Math.random() * 1000);

    const dialogRef = this.dialog.open(component, {
      ...data,
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.custom-dialog-container') as HTMLElement;
      if (dialogElement) {
        dialogElement.style.zIndex = 'auto';
      }
    });

    return dialogRef;
  }

  close(loaderId: string) {
    this.dialog.openDialogs.forEach(dialog => {
      if (dialog.id === loaderId) {
        dialog.close();
        console.log('Dialog closed');
      }
    });
  }
}
