import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { UploadBlock } from 'src/app/models/upload-block';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseUtilitiesService } from 'src/app/services/firebase-utilities.service';
import { environment } from 'src/environments/environment';

import { UIMessagingService } from '../../../services/uimessaging.service';
import { FlatNode } from './../../../models/FlatNode';
import { Folder } from './../../../models/Folder2';
import { UploadTreeNode } from './../../../models/UploadTreeNode';
import { UploadHelperService } from './../../../services/upload-helper.service';
import { UtilsService } from './../../../services/utils.service';
import { SessionService } from './../../../session.service';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { CustomActions } from 'src/app/dictionaries/CustomActions';

// TODO: Implement a Logging Service.
let TREE_DATA: UploadTreeNode[] = [];
const notPlayableVideoFileExtensions = ['vob', 'mpeg'];
@Component({
  templateUrl: './uploaddialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./uploaddialog.component.scss'],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  nitems: number;
  nodeInEdit: boolean;
  inEditUnComplete: boolean;
  allFolders: any;
  section: any;
  clioMatterId: any;
  allowedExtensionsMessage = `<b>Allowed extensions:</b> png, gif, pdf, bmp, tiff, jpeg, vob, wav, mp3, csv, docx, pptx, xlsx, doc, xls, ppt, msi, msg, mp4, mpeg`;
  requiredFieldsValidationMessage = `There are some required fields (<span class="red-text">*</span>) pending to be filled.`;

  @ViewChild('uploadDiskButton') uploadDiskButton: MatButton;
  changeFolderPermission: boolean;
  dicomdirFileName: string;
  constructor(
    private uiMessaging_$: UIMessagingService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private utils_$: UtilsService,
    private uploadHelper_$: UploadHelperService,
    private auth_$: AuthService,
    private session_$: SessionService,
    private firebase_$: FirebaseUtilitiesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.section = data.section || false;
    this.clioMatterId = data.clioMatterId || data.clioDocId || false;
    this.dataSource.data = TREE_DATA;
    this.switchUploadFilesButton(this.dataSource.data);
    this.suggestionOpen = false;
    this.uploadTypes = data.uploadTypes;
    this.fdate = '';
    this.clicked = false;
    this.basicfile = data.basicfile;
    this.basicfolder = data.basicfolder;
    this.casename = data.action_params.caseName;
    this.clientName = data.clientName;
    this.creator = data.action_params.creator;
    this.currentFolder = data.currentFolder;
    this.files = {};
    this.fdesc = '';
    this.action_params = data.action_params;
    this.dicomdirFileName = 'DICOMDIR';
    this.formDoc = this.fb.group({
      description: [this.description, []],
      uploadTypes: [this.uploadTypes, []],
      fdate: [this.fdate, []],
      fdesc: [this.fdesc, []],
      basicfile: [this.basicfile, []],
      basicfolder: [this.basicfolder, []],
      files: [this.files, []],
    });

    this.dragAndDropControl = false;
    this.directory = false;
    this.allowDirs = false;
    this.multiple = false;

    this.nitems = 0;
  }
  /**
   * BASIC CONTEXT MENU
   */
  title = 'context-menu';
  isDisplayContextMenu: boolean;
  isDisplay2ndContextMenu: boolean;
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  rightClick2ndMenuPositionX: number;
  rightClick2ndMenuPositionY: number;
  matMenu: MatMenu;

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  menuTopLeftPosition = { x: '0', y: '0' };

  @Output() finish = new EventEmitter();
  @Output() stackPush = new EventEmitter();
  onFileSelection = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('uploadgroups') uploadgroups: MatSelectionList;
  @ViewChild('fileBrowser') fileBrowser: ElementRef;
  @ViewChild('picker', { static: true }) picker;

  nodeFxLayout: any;
  editingNode: boolean;
  initialNodeValues: { fdate: any; desc: any; target: any };
  targetFolder: any;
  chooseFiles: boolean;
  suggestionOpen = false;
  dragAndDropControl: boolean;
  formDoc: FormGroup;
  fdate: string;
  uploadTypes: any;
  basicfile: string;
  basicfolder: string;
  uploadFileType: string;
  fdesc: string;
  files: Object;
  type: string;
  description: string;
  casename: string;
  clientName: string;
  creator: any;
  directory: boolean;
  allowDirs: boolean;
  multiple: boolean;
  action_params: any;
  clicked: boolean;
  currentFolder: Folder;
  uploadBlocks: any;
  targetDirectoryChooser: boolean;
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );

  private _transformer = (node: UploadTreeNode, level: number) => {
    let answer;
    const target = !node.children
      ? {
          folderId: node.target.folderId === 'all' || node.target.folderId === '' ? '' : node.target.folderId,
          folderName: node.target.folderId === 'all' || node.target.folderId === '' ? '' : node.target.folderName,
        }
      : { folderName: '', folderId: '' };

    answer = {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      fdate: node.fdate,
      edit: node.edit,
      desc: node.desc,
      ugid: node.ugid,
      level: level,
      inprogress: node.inprogress,
      entryUID: node.entryUID,
      toUpload: node.toUpload,
      target: target,
    };

    if (level === 1) answer.fileUploadCode = node.fileUploadCode;

    return answer;
  };

  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  // tslint:disable-next-line: member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      'z-index': '9',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`,
    };
  }

  filesWithoutTarget(): boolean {
    return (
      this.treeControl.dataNodes
        .filter(node => node.expandable === false)
        .filter(node => !node.target.folderId)
        .filter(node => node['toUpload'] !== false).length === 0
    );
  }

  getUploadButtonLabel() {
    const numberOfSelectedItems = this.dataSource.data
      .map(g => g.children.filter(o => o.toUpload !== false).length)
      .reduce((a, b) => a + b);
    return `UPLOAD ${numberOfSelectedItems} file(s) to Client Profile`;
  }

  ngOnDestroy() {
    this.validateRequiredFields();
  }

  async ngOnInit() {
    this.changeFolderPermission = this.auth_$.userData.value['role'] !== UserRoles.client;
    this.clicked = false;
    this.nodeFxLayout = 'row';
    this.editingNode = false;

    this.allFolders = await this.firebase_$.getAllFolders(this.casename);
    this.uploadHelper_$.scannedBlocksSource.subscribe({
      next: (result: any) => {
        if (result.length) {
          this.handleScannedBlocksSourceChange(result);
        }
      },
      error: (err: any) => console.log('error: ', err),
      complete: () => console.log('completed'),
    });

    if (this.section) {
      switch (this.section) {
        case CustomActions.uploadDicomDisk:
          console.log(CustomActions.uploadDicomDisk);
          break;
        default:
          break;
      }
    }
  }

  browseFiles(event, formcontrolname) {
    this.openInfoDialog(event, formcontrolname);
  }

  buildTreeData(): void {
    const allowedStatuses = ['new', 'inprogress'];
    this.uploadBlocks = this.uploadHelper_$
      .getScannedUploadBlocksList()
      .filter(({ status }) => allowedStatuses.includes(status))
      .filter(({ casename }) => casename === this.casename);

    if (this.uploadBlocks.length === 0) {
      return;
    }

    TREE_DATA = [];
    const treeData = [];

    this.uploadBlocks.forEach(uploadBlock => {
      const { ugid, fdate, ftime, edit, fdesc, status } = uploadBlock;
      const inprogress = status === 'inprogress' ? true : false;

      // FIXME: Have to fix this, inprogress and status should not live together, choose one.
      const groupNode = {
        name: ugid,
        ugid: ugid,
        fdate: fdate,
        ftime: ftime,
        edit: edit,
        desc: fdesc || '',
        inprogress: inprogress,
        children: this.utils_$.buildChildren(ugid, inprogress, uploadBlock.blockFiles, this.currentFolder || ''),
      };
      treeData.push(groupNode);
    });

    TREE_DATA = treeData;
    this.dataSource.data = treeData;
    this.treeControl.expandAll();
    this.uploadBlocks = [];
    TREE_DATA = [];
  }

  cancelEdit(node) {
    // Go back to initial values.
    node.desc = this.initialNodeValues.desc;
    node.fdate = this.initialNodeValues.fdate;

    node.edit = false;
    this.unHideAll();
    this.nodeFxLayout = 'row';
    this.editingNode = false;
  }

  /**
   * Check all the uploadBlocks and the files inside in order to check if there
   * is at least one file without desc or fdate fields empty.
   * @param scannedBlocksSource is the source to search in.
   */
  checkUnfilledFiles(scannedBlocksSource): boolean {
    const unfilledEntries = [];
    scannedBlocksSource.forEach(uploadBlock => {
      uploadBlock.blockFiles.forEach(blockFile => {
        const { desc, fdate } = blockFile.entry;
        if (desc === '' || fdate === '') {
          unfilledEntries.push(blockFile.entry);
        }
      });
    });
    return unfilledEntries.length === 0;
  }

  closeAllWindows() {
    this.uploadHelper_$.clearScannedBlocksSource();
    this.dialogRef.close('refresh');
  }

  close($event) {
    this.uploadHelper_$.clearScannedBlocksSource();
    this.dialogRef.close('refresh');
  }

  deleteGroup(ugID: string) {
    this.uploadHelper_$.removeUploadBlock(ugID);
  }

  deleteNode(event, node) {
    event.preventDefault();
    const { ugid, name } = node;
    this.uploadHelper_$.removeNode(name, ugid);
    const newData = [];
    this.dataSource.data.forEach(group => {
      if (group.ugid === ugid) {
        const newChildren = group.children.filter(child => child.name !== name);
        group.children = newChildren;
      }
      if (group.children.length) {
        newData.push(group);
      }
    });

    this.dataSource.data = newData;
    this.switchUploadFilesButton(this.dataSource.data);
    this.treeControl.expandAll();
  }

  deleteChild(event, node) {
    event.preventDefault();
    const { ugid, name } = node;
    this.uploadHelper_$.removeNode(name, ugid);
    const newData = [];
    this.dataSource.data.forEach(group => {
      if (group.ugid === ugid) {
        const newChildren = group.children.filter(child => child.name !== name);
        group.children = newChildren;
      }
      if (group.children.length) {
        newData.push(group);
      }
    });

    this.dataSource.data = newData;
    this.switchUploadFilesButton(this.dataSource.data);
    this.treeControl.expandAll();
  }

  checkEmpty(control) {
    if (control.value !== '') {
      control.parentElement.parentElement.parentElement.parentElement.classList.remove('highlighted-control');
      this.setFolderChooserFocus(control);
    }
  }

  setFolderChooserFocus(control) {
    const nextControl = control.offsetParent.offsetParent.offsetParent.nextElementSibling;
    if (nextControl) {
      nextControl.classList.add('hightlighted-control');
    }
  }

  setFocusToNextControl(control) {
    setTimeout(() => {
      const nextSibling = control.offsetParent.offsetParent.offsetParent.nextElementSibling;
      nextSibling.classList.add('highlighted-control');
      const element = nextSibling.querySelector('input.mat-input-element');
      element ? element.focus() : console.log('Element not found');
    }, 100);
  }

  fieldInputChange_date(event, node) {
    node.fdate = this.utils_$.getDateToString(event.value);
  }

  fieldInputChange_content(event, node) {
    node.desc = event.target.value;
  }

  hideNoEdited() {
    setTimeout(() => {
      document.querySelectorAll('mat-tree-node:not(.edit-mode)').forEach(el => el.classList.add('hidden'));
    }, 50);
  }

  unHideAll() {
    document.querySelectorAll('mat-tree-node:not(.edit-mode)').forEach(el => el.classList.remove('hidden'));
  }

  // NOTE: When you click on file selected to be uploaded and EDIT its fields.
  gotoEdit(node) {
    this.initialNodeValues = {
      fdate: node.fdate,
      desc: node.desc,
      target: node.target,
    };

    // NOTE: look for node.name and disable children nodes edit field.
    this.editingNode = true;
    if (node.level === 0) {
      TREE_DATA.forEach(item => {
        if (item.name === node.name) {
          item.edit = true;
          item.children.forEach(child => {
            child.edit = false;
          });
        }
      });
      this.dataSource.data = TREE_DATA;
      this.switchUploadFilesButton(this.dataSource.data);
      this.treeControl.expandAll();
    } else {
      node.edit = true;
      this.nodeInEdit = true;
      this.evaluateCompletion(node);
    }
    setTimeout(() => {
      this.folderChooserAppeareance(node);
      this.setFocusOnfirstEmptyField(node);
      this.hideNoEdited();
    }, 200);
  }

  folderChooserAppeareance(node) {
    if (node.target.folderId !== '') {
      const inputElement = <HTMLInputElement>document.querySelector(`#folderChooser_${node.ugid}`);
      if (!inputElement) return;
      const length = inputElement.value.length;
      inputElement.style.width = `${length * 8}px`;
    }
  }

  evaluateCompletion(node) {
    if (node.fdate !== '' && node.desc !== '' && node.target.folderId) {
      this.inEditUnComplete = false;
    } else {
      this.inEditUnComplete = true;
    }
  }

  setFirstFieldFocus(node) {
    const element = <HTMLElement>document.querySelector('mat-tree-node.edit-mode input.mat-datepicker-input');
    element.parentElement.parentElement.parentElement.parentElement.classList.add('highlighted-control');
    element.focus();
  }

  setFocusOnfirstEmptyField(node) {
    const highlightingClass = 'highlighted-control';
    const element = <HTMLInputElement>document.querySelector('mat-tree-node.edit-mode input.mat-datepicker-input');
    if (element.value === '') {
      element.parentElement.parentElement.parentElement.parentElement.classList.add(highlightingClass);
      element.focus();
    } else {
      const secondElement = element.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
      const inputElement = secondElement.querySelector('input');
      if (inputElement.value === '') {
        secondElement.classList.add(highlightingClass);
        inputElement.focus();
      } else {
        const thirdElement = secondElement.nextElementSibling;
        if (thirdElement.querySelector('input').value === '') {
          thirdElement.classList.add(highlightingClass);
          thirdElement.querySelector('input').focus();
        } else {
          thirdElement.querySelector('input').style.width = thirdElement.querySelector('input').value.length * 8 + 'px';
          return false;
        }
      }
    }
  }

  getDateFromString(dateString) {
    return new FormControl(new Date(dateString));
  }

  /**
   * Evaluates entries searching for DICOMDIR or VIDEO files to know what kind
   * of folder the user is atempting to upload.
   * @param entries has the file entries.
   * @returns the uploadBlock ready to be uploaded.
   */
  async generateUploadBlock(entries): Promise<UploadBlock> {
    const DICOMDIRFile = entries.find(el => el.name === this.dicomdirFileName);
    const isVIDEODisk = entries.find(el =>
      notPlayableVideoFileExtensions.includes(this.utils_$.getFileExtension(el.name)),
    );
    const contextParams = {
      creator: this.creator,
      fdate: this.fdate,
      fdesc: this.fdesc,
      clientName: this.clientName,
      casename: this.casename,
      currentFolder: this.currentFolder,
    };

    const protoUploadBlock = await (async () => {
      if (DICOMDIRFile) return await this.uploadHelper_$.getDICOMDISKUploadBlock(DICOMDIRFile, entries, contextParams);
      const fileEntries = isVIDEODisk ? this.uploadHelper_$.getVIDEODISKUploadBlock(entries) : entries;
      return await this.uploadHelper_$.createUploadBlock(fileEntries, contextParams);
    })();

    if (!protoUploadBlock) {
      console.error('protoUploadBlock is undefined');
      return null;
    }
    return new UploadBlock(protoUploadBlock);
  }

  handleBeforeUnLoad(event) {
    event.preventDefault();
    event.returnValue = 'You have attempted to leave this page. There are processes running. Are you sure?';
  }

  selectNode(node) {
    this.treeControl.dataNodes.forEach(
      nodeIn => (nodeIn.selected = nodeIn.ugid === node.ugid && nodeIn.expandable === false ? true : false),
    );
    this.targetDirectoryChooser = true;
  }

  async cleanNotAllowedFiles(entries: File[]): Promise<File[]> {
    const promises = [];
    entries.forEach((entry: File) => promises.push(this.utils_$.getFileMimeType(entry)));
    const cleanedNotAllowedFiles = [];
    await Promise.all(promises).then(r => {
      r.forEach((e, i) => {
        if (typeof e === 'string') {
          cleanedNotAllowedFiles.push(entries[i]);
        }
      });
    });

    if (cleanedNotAllowedFiles.length === 0) {
      const message = `Forbidden files has been removed, please try again.`;
      this.uiMessaging_$.toastMessage(message, 'ERROR');
      this.suggestionOpen = true;
    }

    return cleanedNotAllowedFiles;
  }

  /**
   * Happens when the user clicked on Select FILES
   * and choosed a file or files on BrowseFiles window.
   */
  async handleFileSelection(event: Event): Promise<void> {
    const entries = Array.from((<HTMLInputElement>event.target).files);

    if (!entries.length) {
      this.uiMessaging_$.toastMessage('The selection is empty, please try again.', 'ERROR');
      this.suggestionOpen = true;
      return;
    }

    const filteredEntries = entries.filter(entry => !(<any>this.basicfile).filter(e => e.name === entry.name).length);
    if (filteredEntries.length === 0) {
      const message = `Duplicated files has been selected, please try again.`;
      this.uiMessaging_$.toastMessage(message, 'ERROR');
      this.suggestionOpen = true;
      return;
    }

    const allowedFiles = await this.cleanNotAllowedFiles(filteredEntries);
    const diff = filteredEntries.length - allowedFiles.length;
    if (diff > 0) {
      this.uiMessaging_$.toastMessage(
        `For security purposes we do not allow this type of file(s). ${diff} file(s) has been removed.`,
        'IMPORTANT',
      );
    } else if (diff === 0 && allowedFiles.length === 0) {
      this.uiMessaging_$.toastMessage(
        `For security purposes we do not allow certain type of file(s). Your stack is empty.`,
        'IMPORTANT',
      );
    }

    // NOTE: Create and push the Upload Block to the ScannedBlocksSource.
    const block = {
      creator: this.creator,
      fdate: this.fdate,
      fdesc: this.fdesc,
      clientName: this.clientName,
      casename: this.casename,
      type: '1',
    };

    const uploadBlock = new UploadBlock(await this.uploadHelper_$.createUploadBlock(allowedFiles, block));
    const pushUpload = this.uploadHelper_$.pushUploadBlock(uploadBlock);
    this.suggestionOpen = !pushUpload;
  }

  /**
   * Happens when the user clicked on Select DISK/FOLDER
   * and choosed a folder on BrowseFiles window.
   */
  async handleFolderSelection(event: Event) {
    const inputElement = <HTMLInputElement>event.target;
    const entries = Array.from(inputElement.files);

    inputElement.value = '';
    if (!entries.length) {
      console.log('No file has been selected');
      this.suggestionOpen = true;
      return;
    }

    const uploadBlock = await this.generateUploadBlock(entries).catch(e => {
      console.error(e);
      this.suggestionOpen = true;
    });

    if (!uploadBlock) {
      console.error('uploadBlock is undefined');
      return;
    }

    if (!uploadBlock.blockFiles) {
      this.uiMessaging_$.toastMessage('This upload block has no files inside. Please give it a second try', 'ERROR');
      return;
    }

    this.uploadHelper_$.pushUploadBlock(uploadBlock);
    this.suggestionOpen = false;
  }

  /**
   * This happens when a new uploadBlock is added, removed or modified in the
   * ScannedBlocksSource.
   */
  handleScannedBlocksSourceChange(scannedBlocksSourceValue) {
    this.chooseFiles = this.checkUnfilledFiles(scannedBlocksSourceValue);
    this.buildTreeData();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  /**
   * Start the upload process.
   */
  async handleUploadClick(event): Promise<void> {
    // Validate when the Total Studies exceeded the free plan quota.

    const ownerPlanCode = this.data.ownerPlanCode;

    if (ownerPlanCode === 'free') {
      const totalStudiesLimit = 6;
      // Get the total studies of the owner
      const totalStudies = await this.firebase_$.getOwnerTotalStudies(this.data.ownerID);
      if (totalStudies >= totalStudiesLimit) {
        this.uiMessaging_$.toastMessage(
          'You have exceeded the free plan quota. Please upgrade your plan to continue uploading files.',
          'ALERT',
        );
        return;
      } else {
        // The user has not reached the limit yet.
        // Lets count the selected studies.
        const totalStudiesToUpload = [].concat.apply(
          [],
          this.dataSource.data.map(group => group.children),
        ).length;

        if (totalStudies + totalStudiesToUpload > totalStudiesLimit) {
          this.uiMessaging_$.toastMessage(
            'You have exceeded the free plan quota. Please upgrade your plan to continue uploading files.',
            'ALERT',
          );
          return;
        }
      }
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.clicked) return;
    this.clicked = true;

    if (!this.runValidations()) {
      this.uiMessaging_$.toastMessage(
        `Some validation is throwing an error please check required fields of files in lists`,
        'ALERT',
      );
      return;
    }

    // FIXME: Work in progress.
    // this.clicked = true;
    this.switchFilesToInProgress(this.dataSource.data);
    this.disableSessionTimeOut();

    // START THE UPLOAD.
    const options = {
      section: this.section,
      target: this.clioMatterId || null,
      owner: this.data.ownerID,
      reference: 'matter',
    };

    this.uploadHelper_$
      .startUpload(this.targetFolder || this.currentFolder, options)
      .then(result => this.uploadFinished())
      .catch(err => {
        throw err;
      });
  }

  enableSessionTimeOut() {
    this.setSessionTimeOut();
  }

  setSessionTimeOut() {
    if (!this.session_$.getSessionTimeOutState()) {
      return false;
    }
    this.session_$.clearSessionTimeOutState();
    this.session_$.sessionTimeout = setTimeout(() => {
      this.session_$.handleTimeEnd(v => {
        switch (v) {
          case 'session_end':
            this.session_$.dialog.closeAll();
            this.session_$.clearSessionTimeOutState();
            this.session_$.clearSessionTimeout();
            this.auth_$.logout();
            break;
          case 'session_continue':
            this.session_$.dialog.closeAll();
            this.setSessionTimeOut();
            break;
          default:
            this.session_$.dialog.closeAll();
            this.setSessionTimeOut();
            break;
        }
      });
    }, environment.config.sessionTimeLimit);
  }

  disableSessionTimeOut() {
    this.session_$.disableSessionTimeOut();
  }

  onTargetFolderSelection(ev, quit?: number) {
    this.targetFolder = Object.fromEntries(new Map(ev.split('*').map(item => item.split(','))));
    this.updateTargetForSelectedNodes();
    if (quit === 1) this.targetDirectoryChooser = !this.targetDirectoryChooser;
    this.fireFolderChooserInput(this.targetFolder.folderName.toString());
  }

  fireFolderChooserInput({ length }) {
    const { ugid } = this.treeControl.dataNodes.filter(n => n.edit).filter(n => !n.expandable)[0];
    const htmlElement = <HTMLInputElement>document.querySelector(`#folderChooser_${ugid}`);
    htmlElement.style.width = `${length * 8.3}px`;
  }

  allUnSelected(): boolean {
    return this.treeControl.dataNodes.filter(node => node.selected).length === 0;
  }

  updateTargetForSelectedNodes() {
    this.treeControl.dataNodes.filter(node => node.selected).forEach(node => (node.target = this.targetFolder));
  }

  clickedOutsideMenu() {
    this.isDisplay2ndContextMenu = false;
  }

  clearSelection() {
    this.treeControl.dataNodes.filter(node => node.selected).forEach(node => (node.selected = false));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openInfoDialog(event, formcontrolname) {
    (<HTMLElement>(
      document.querySelector(`app-input-file[formcontrolname="${formcontrolname}"] input[type="file"`)
    )).click();
  }

  /**
   * Checks if fdate and desc are empty.
   */
  runValidations(): boolean {
    return this.validateRequiredFields();
  }

  saveGroup(node) {
    if (!node.fdate) {
      const message = `Date of file is a required field, please fill it before save.`;
      this.uiMessaging_$.toastMessage(message, 'VALIDATION');
      return;
    }

    if (node.desc === '') {
      const message = `File Content is a required field, please fill it before save.`;
      this.uiMessaging_$.toastMessage(message, 'VALIDATION');
      return;
    }

    if (!node.target.folderId) {
      const message = `Folder is a required field, please choose one before save.`;
      this.uiMessaging_$.toastMessage(message, 'VALIDATION');
      return;
    }

    // When both fields are not empty.
    this.editingNode = false;
    this.nodeInEdit = false;
    this.nodeFxLayout = 'row';
    const { ugid } = this.dataSource.data.find(item => item.ugid === node.ugid);
    this.uploadHelper_$.updateScannedBlockSource(ugid, node);
    node.edit = false;
    this.unHideAll();
    return;
  }

  /**
   * Make upload stacks be forbidden to be edited
   * while the upload itself is in progress.
   */
  switchFilesToInProgress(data) {
    const updateData = (lData, lUploadBlockFiles) => {
      lData.forEach(group => {
        group.children.forEach(file => {
          file.inprogress = true;
          file.toUpload =
            lUploadBlockFiles.find(uploadBlockFile => uploadBlockFile.entry.entryUID === file.entryUID)?.toUpload ===
            false
              ? false
              : true;
        });
      });
      return lData;
    };

    const uploadBlockFiles = this.uploadHelper_$.uploadBlocksSource
      .getValue()
      .filter(uploadBlock => data.map(group => group.ugid).includes(uploadBlock.ugid))
      .map(uploadBlock => uploadBlock.blockFiles)
      .reduce((acc, val) => acc.concat(val), []);

    this.dataSource.data = updateData(data, uploadBlockFiles);
    this.switchUploadFilesButton(this.dataSource.data);
    // this.treeControl.expandAll();
    return;
  }

  switchUploadFilesButton(data) {
    this.chooseFiles = data.length === 0;
  }

  uploadsPending(): boolean {
    return this.uploadHelper_$.getScannedUploadBlocksList().filter(block => ['new'].includes(block.status)).length > 0;
  }

  uploadFinished() {
    if (!this.uploadsPending() && !this.uploadsRunning()) {
      window.removeEventListener('beforeunload', this.handleBeforeUnLoad, false);
      // Have to clear finished from the list.
      this.clicked = false;
      this.uploadHelper_$.removeCompleted();
      this.closeAllWindows();
    }

    window.addEventListener('beforeunload', this.handleBeforeUnLoad, false);
    this.finish.emit({ action: 'getfiles' });
    this.enableSessionTimeOut();
  }

  uploadsRunning(): boolean {
    return (
      this.uploadHelper_$.getScannedUploadBlocksList().filter(block => ['inprogress'].includes(block.status)).length > 0
    );
  }

  validateRequiredFields() {
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const element = this.dataSource.data[index].children;
      for (let idx = 0; idx < element.length; idx++) {
        if (!element[idx].fdate) {
          return false;
        }
        if (element[idx].desc === '') {
          return false;
        }
      }
    }
    return true;
  }

  switchScannedUploadBlockFile(ugid, entryUID, checked = false) {
    // To show params data.
    console.log('switchScannedUploadBlockFile', {
      ugid,
      entryUID,
      checked,
    });
    const uploadBlocks = this.uploadHelper_$.scannedBlocksSource.getValue();
    const file = uploadBlocks.find(block => block.ugid === ugid).blockFiles.find(f => f.entry.entryUID === entryUID);

    if (!file) {
      if (window.location.hostname === 'app.nuagedx.com') {
        const rg4js = require('raygun4js');
        rg4js('send', {
          error: new Error('switchScannedUploadBlockFile file not found'),
        });
      }
      return;
    }

    if (!checked) {
      file.toUpload = checked;
    } else {
      delete file.toUpload;
    }

    this.uploadHelper_$.uploadBlocksSource.next(uploadBlocks);
  }

  checkNode(ev, node) {
    console.log('checkNode', ev, node);
    this.switchScannedUploadBlockFile(node.ugid, node.entryUID, ev.checked);
    this.updateDataSource(ev.checked, node.ugid, node.entryUID);
  }

  updateDataSource(checked, ugid, entryUID) {
    const group = this.dataSource.data.find(group => group.ugid === ugid);
    const file = group.children.find(file => file.entryUID === entryUID);
    file.toUpload = checked;
    this.dataSource.data = [...this.dataSource.data];
    this.treeControl.expandAll();
  }

  getContextParams(ndate) {
    return {
      creator: this.creator,
      fdate: this.fdate.length ? this.fdate : ndate,
      fdesc: this.fdesc,
      clientName: this.clientName,
      casename: this.casename,
      currentFolder: this.currentFolder,
    };
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
}
