<mat-drawer-container class="example-container"
                      autosize
                      [hasBackdrop]="true">
  <mat-drawer #drawer
              style="border-top: red 5px solid"
              mode="push">
    <div *ngIf="userData"
         class="mdc-drawer__header">
      <h3 style="margin-bottom:0">
        {{userData.userName}}
      </h3>
      <h6 class="userdata-email">
        {{ userData.userEmail }}
      </h6>
      <h6 class="mdc-drawer__subtitle subtitle">
        <button *ngIf="roleCheck(5)"
                class="small-button"
                aria-label="Edit profile"
                mat-icon-button
                matTooltip="Edit user."
                (click)="editProfile($event)">
          <mat-icon>
            manage_accounts
          </mat-icon>
        </button>
        {{ userData.userDBRole }}
        <a *ngIf="userData.lpm && userData.lpm === 'clio' && roleCheck(6)"
           class="clio-logo"
           (click)="navigateBackToClio()"
           rel="noopener noreferrer"
           target="_self"
           title="Clio Logo">
          <img alt="Clio logo"
               src="./../../../assets/png/clio-blue-square.png">
          Back to Clio
        </a>
        <!-- Print the user plan here -->
        <div class="plan-type">
          <a class="plan-type-link"
             (click)="upgradeYourPlan()">
            <mat-icon>
              star
            </mat-icon>
            Plan:
            <b>
              {{planText[userData.plancode]}}
            </b>
          </a>
        </div>
      </h6>
    </div>
    <mat-list *ngIf="userData"
              role="list"
              [ngClass]="'sidebar-menu-list'">
      <div id="usersMenu"></div>
      <mat-list-item *ngIf="userData.userDBRole === UserRoles.client"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="openClient()">
          <i class="material-icons"
             aria-hidden="true">
            account_circle
          </i>
          <span>
            Client/Matter Profile
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="![UserRoles.superuser, UserRoles.client].includes(userData.userDBRole) && !ownerNeeded"
                     [disabled]="!activateMenuItems">
        <a name="clients-matters"
           fxLayout="row"
           (click)="navigateToClient()">
          <i class="material-icons"
             aria-hidden="true">
            account_circle
          </i>
          <span>
            Clients/Matters
          </span>
          <mat-icon [ngClass]="hasNotifications() ? 'blinking-icon' : 'hidden'">
            notifications
          </mat-icon>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="![UserRoles.superuser, UserRoles.client].includes(userData.userDBRole) && ownerNeeded"
                     [disabled]="!activateMenuItems">
        <a name="clients-matters"
           fxLayout="row"
           (click)="navigateToAttorneysLawFirms()">
          <i class="material-icons"
             aria-hidden="true">
            work
          </i>
          <span>
            Attorneys
          </span>
          <!-- <mat-icon [ngClass]="hasNotifications() ? 'blinking-icon' : 'hidden'">notifications</mat-icon> -->
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="userData.userDBRole === UserRoles.superuser"
                     id="reportGen"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateGenerateReport()">
          <i class="material-icons"
             aria-hidden="true">
            insert_drive_file
          </i>
          <span>
            Generate Report
          </span>
        </a>
      </mat-list-item>

      <mat-list-item *ngIf="userData.userDBRole === UserRoles.superuser"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="createUser(UserRoles.owner)">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Create Owner
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(7)"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToAdmin()">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Admin List
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(1) && isNotSilver()"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToClientUsers()">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Clients List
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="[UserRoles.superuser].includes(userData.userDBRole)"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToOwner()">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Owners List
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(1)"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToConsultant()">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Consultant List
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(1)"
                     id="buttonSectionIdent"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToAssociate()">
          <i class="material-icons"
             aria-hidden="true">
            perm_identity
          </i>
          <span>
            Associate List
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="(userPermissions && createuser) || roleCheck(2)"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="navigateToCreateClientMatter()">
          <i class="material-icons"
             aria-hidden="true">
            accessibility
          </i>
          <span>
            Create Client/Matter
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(3)"
                     [disabled]="!activateMenuItems">
        <a name="create-user"
           fxLayout="row"
           (click)="navigateToCreateAdminUser()">
          <i class="material-icons"
             aria-hidden="true">
            accessibility
          </i>
          <span>
            Create User
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(4)"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="VideoConferencing()">
          <i class="material-icons"
             aria-hidden="true">
            video_call
          </i>
          <span>
            Video Conferencing
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(5) && userData.plancode !== 'platinumyearly'"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="upgradeYourPlan()">
          <i class="material-icons"
             aria-hidden="true">
            upgrade
          </i>
          <span>
            Upgrade your plan
          </span>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="roleCheck(5)"
                     [disabled]="!activateMenuItems">
        <a fxLayout="row"
           (click)="openAuditLog()">
          <i class="material-icons"
             aria-hidden="true">
            timeline
          </i>
          <span>
            Audit Log
          </span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a name="logout"
           fxLayout="row"
           (click)="auth_$.logout()">
          <i class="material-icons"
             aria-hidden="true">
            power_settings_new
          </i>
          <span>
            Logout
          </span>
        </a>
      </mat-list-item>
      <div id="ze-snippet-container"></div>
    </mat-list>
  </mat-drawer>

  <mat-drawer-content>
    <header id="app-bar"
            [ngClass]="['mdc-top-app-bar', 'header-bar']">
      <div class="drawer mdc-top-app-bar__row">
        <section [ngClass]="['mdc-top-app-bar__section', 'mdc-top-app-bar__section--align-start']">
          <button *ngIf="userEnabled"

                  id="nuageMenu"
                  class="mdc-button mdc-top-app-bar__navigation-icon"

                  [ngClass]="{ hidden: maintenance }"
                  (click)="drawer.toggle()">
            <span class="mdc-button__label">
              <span class="material-icons menu">
                menu
              </span>
            </span>
          </button>

          <span *ngIf="userData && loaded"
                class="mdc-top-app-bar__title">
            <a href="/">
              <img class="image-size"
                   src="../../assets/Nuage-Logo.png"
                   title="Nuage">
            </a>
          </span>
        </section>

        <a *ngIf="noUserData && this.currentUrl !== '/login'"

           class="gotologin-button"
           color="warn"

           mat-flat-button
           (click)="goToLogin()">
          Go to login page
        </a>
      </div>
    </header>
    <div class="mdc-top-app-bar--fixed-adjust">
      <div class="footer"
           fxLayout="column">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
