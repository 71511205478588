import { ErrorHandler } from '@angular/core';

// Create a new ErrorHandler and report an issue straight to Raygun
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any): void {
    if (window.location.hostname === 'app.nuagedx.com') {
      const rg4js = require('raygun4js');
      rg4js('send', { error: e });
    }
    console.error(e);
  }
}
