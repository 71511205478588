import 'firebase/auth';
import 'firebase/firestore';
import { RedirectionService } from 'src/app/services/redirection.service';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ConfirmationDialogComponent } from '../components/ui/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '../dialog.service';
import { LogLevelsDictionary } from '../dictionaries/LogLevels';
import { UserRoles } from '../dictionaries/UserRoles';
import { LoaderComponent } from '../loader/loader.component';
import { LogService } from '../log.service';
import { User } from '../models/User';
import { NotificationsService } from '../notifications.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';
import { ProgressLoaderComponent } from '../progress-loader/progress-loader.component';

const dbUsers = firebase.firestore().collection('users');
const dbPatients = firebase.firestore().collection('patients');
const dbPlans = firebase.firestore().collection('plans');
const DEFAULT_PLAN = 'fp';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  discsFolderName = 'Discs';
  auth = firebase.auth();
  firestore = firebase.firestore();
  uid: string;
  currentUserSignedIn: firebase.User;
  public currentUser = new BehaviorSubject(null);
  user = this.currentUser.asObservable();
  errorAuth: Subject<string> = new Subject();
  public userData = new BehaviorSubject({});
  searchComponent = false;
  public drawer;
  clioTokenRenewRunning = false;
  loader: MatDialogRef<unknown, any>;
  userIsSignedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  permissions: firebase.firestore.DocumentData[];
  redirectURL: string;
  preventRedirections: boolean;
  notificationsTimer: NodeJS.Timeout;
  clientProfileActions: BehaviorSubject<any> = new BehaviorSubject(null);
  clientProfileFiles: BehaviorSubject<any> = new BehaviorSubject([]);
  clientProfileNotifications: BehaviorSubject<any> = new BehaviorSubject([]);
  clientProfileActionsData: any;
  currentPatient: any = false;
  currentFolder: any = false;
  confirmationDialogExists: boolean;
  clientProfileSelectedFiles: any[] = [];

  constructor(
    private sessionStorage_$: SessionStorageService,
    private redirectiron_$: RedirectionService,
    private localStorage_$: LocalStorageService,
    public ng: NgZone,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private log_$: LogService,
    private utils_$: UtilsService,
    private dialog_$: DialogService,
    private uiMessaging_$: UIMessagingService,
    private toastMessage_$: UIMessagingService,
    private notifications_$: NotificationsService,
  ) {
    this.observeUser();
  }

  getOwnerPlanCode(ownerID) {
    if (!ownerID) return DEFAULT_PLAN;
    return dbUsers
      .where('uid', '==', ownerID)
      .get()
      .then(res => res.docs[0].data().plancode);
  }

  async getOwnerName(ownerID: any) {
    try {
      const res = await this._getUserByUID(ownerID, 'getOwnerName');
      return res.docs[0].data().name;
    } catch (err) {
      return console.error(err);
    }
  }

  isClioRegistered() {
    return this.userData.value['clioregistered'] === true;
  }

  setToastMessage(message, action, options?) {
    this.toastMessage_$.toastMessage(message, action, options || null);
  }

  getPermissions(label) {
    const user = this.userData.getValue();
    return user['permissions'] && user['permissions'][label] ? user['permissions'][label] : null;
  }

  syncMyData() {
    return this.userReady(this.userData.getValue(), 'syncMyData');
  }

  setPermissions(data) {
    const user = this.userData.getValue() || {};
    this.userData.next({ ...user, permissions: data });
  }

  checkIfPasswordIsExpired(docid: string) {
    return firebase.functions().httpsCallable('password-checkIfPasswordIsExpiredV2')({ docid });
  }

  deleteCustomActions(userDocId) {
    const eu = this.sessionStorage_$.getAddToClioEU() || this.userData.getValue()['clioRegion'] === 'eu' || false;
    return firebase.functions().httpsCallable('clio-deleteCustomActions')({ userdocid: userDocId, eu });
  }

  removeClioWebHooks() {
    return firebase.functions().httpsCallable('clio-deleteWebHooks')({ userdocid: this.userData.getValue()['id'] });
  }

  async removeClioToken() {
    const id = this.userData.getValue()['id'];
    await this.deleteCustomActions(id)
      .then(res => {
        console.log('Custom Actions deleted', res);
      })
      .catch(err => {
        console.log('Error deleting custom actions', err);
      });

    await dbUsers
      .doc(id)
      .update({
        ['clioAccessToken']: firebase.firestore.FieldValue.delete(),
        ['clioRefreshToken']: firebase.firestore.FieldValue.delete(),
        ['clioCustomActions']: firebase.firestore.FieldValue.delete(),
        ['lpm']: firebase.firestore.FieldValue.delete(),
        ['client']: firebase.firestore.FieldValue.delete(),
      })
      .catch(err => this.errorAuth.next(err.error.message));

    await this.userReady(this.userData.value, 'removeClioToken');
    this.uiMessaging_$.toastMessage('Clio Access Token Removed', null);
  }

  checkOwnerPlan(): void {
    const userData = this.userData.value;
    const realRole = userData['realRole'];
    const plancode = userData['plancode'];
    const email = userData['email'];
    const timeoutDuration = 5000;

    if (realRole === UserRoles.owner && this.isNullOrUndefined(plancode)) {
      this.uiMessaging_$.toastMessage(
        'This user has no plan, several problems can derivate from this. Please contact the administrator in order to get support.',
        'IMPORTANT',
      );
      const logData = { text: `A user(${email})/role:${realRole} without plan has tried to log in` };

      // STORE A LOG RECORD.
      try {
        this.log_$.storeLog(userData, LogLevelsDictionary.info, logData, 'Error');
      } catch (err) {
        console.error('There was an error with the function storeLog', err);
      } finally {
        console.log('The log has been recorded successfully');
      }

      setTimeout(() => {
        this.logout();
        return;
      }, timeoutDuration);
    }
  }

  async checkIfUserHasPlan(email): Promise<boolean> {
    const { plan } = (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].data();
    return this.isNullOrUndefined(plan);
  }

  callCloudFunction(functionName: string, payload: any, method: string) {
    const url = `${environment.constants.cloudfunctionsURL}${functionName}`;
    switch (method) {
      case 'POST':
        return this.http.post(url, payload);
      case 'GET':
        return this.http.get(url, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
          responseType: 'text',
        });
      default:
        break;
    }
  }

  createClientAndSendPasswordResetEmail(
    email: string,
    password: string,
    newUser: User,
    loggedUser,
    companyName: string,
    role?: string,
  ) {
    const { name } = newUser;
    const { user } = loggedUser;
    const useruid = user.uid;
    const displayName = name;
    const url = `${environment.constants.cloudfunctionsURL}admin-createAppUserV2`;
    const body = {
      email,
      password,
      useruid,
      displayName,
      companyName,
      role,
      ownerID: newUser.ownerID,
      owners: [newUser.ownerID],
      testaccount: newUser.testaccount,
    };

    this.http
      .post(url, body)
      .toPromise()
      .then(response => {
        this.auth
          .sendPasswordResetEmail(email)
          .then(() => {
            newUser.uid = response['uid'];
            this.uiMessaging_$.toastMessage('Owner Created!', null);
          })
          .catch(error => {
            console.log('error :', error);
            this.errorAuth.next(error.message);
          });
      })
      .catch(err => this.errorAuth.next(err.error.message));
  }

  async createPatient(
    firstName: string,
    lastName: string,
    patientId: string,
    legalCaseId: string,
    DateOfBirth: string,
    ownerID?: string,
    metadata?: any,
  ) {
    const patientObject = {
      FirstName: firstName.toLowerCase(),
      LastName: lastName.toLowerCase(),
      caseName: this.cleanBlankSpaces(patientId),
      LegalCaseId: this.cleanBlankSpaces(legalCaseId),
      uidOfCreator: this.auth.currentUser.uid,
      files: [],
      fileSearchQuery: [],
      sharedWith: [],
      ownerID,
      metadata: metadata || {},
    };

    if (!this.isNullOrUndefined(DateOfBirth)) patientObject['DateOfBirth'] = DateOfBirth;

    // Check if the document already exists.
    const doc = await dbPatients.doc(this.cleanBlankSpaces(patientId)).get();

    if (doc.exists) {
      this.uiMessaging_$.toastMessage('Client / Matter already exists', null);

      this.errorAuth.next('Client / Matter already exists');
      return false;
    } else {
      this.errorAuth.next('');
      return dbPatients
        .doc(this.cleanBlankSpaces(patientId))
        .set(patientObject)
        .then(result_1 => {
          this.uiMessaging_$.toastMessage('Client/Matter Added', null);
          this.errorAuth.next('');
          return true;
        })
        .catch(error => {
          this.errorAuth.next('Error Creating Client/Matter');
          console.log('Error Creating Client/Matter', error);
          return false;
        });
    }
  }

  async checkIfConsultantUserExists(email: string): Promise<boolean> {
    const querySnapshot = await dbUsers
      .where('email', '==', email)
      .where('role', '==', UserRoles.consultant)
      .limit(1)
      .get();
    return !querySnapshot.empty;
  }

  // FIXME: Have to do this with a Cloud Function instead.
  async createUserAndSendPasswordResetEmail(password: string, user: User, createdBy: string) {
    let createdUser;
    const userObject = {
      password: password,
      email: user.email,
      role: user.role,
      useruid: this.auth.currentUser.uid,
      phone: user.phone || '',
      displayName: user.name,
      ownerID: user.ownerID,
      owners: [user.ownerID],
      companyName: ``,
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      clientMatter: user.clientMatter || '',
    };

    console.log('userObject :', userObject);

    try {
      createdUser = await this.callCloudFunction('admin-createAppUserV2', userObject, 'POST').toPromise();
    } catch (err) {
      console.log('err :', err);
      this.uiMessaging_$.toastMessage(`Error Creating User: ${err.error.message}`, null);
      this.errorAuth.next(err.message);
      return err;
    } finally {
      console.log('The user has been created successfully');
    }

    const userData = this.userData.getValue();
    const userEmail = user.email;
    const userRole = user.role;
    const logData = { text: `A new user(${userEmail})/role:${userRole} has been created` };
    const action = 'User Creation';

    // STORE A LOG RECORD.
    try {
      this.log_$.storeLog(userData, LogLevelsDictionary.info, logData, action);
    } catch (err) {
      console.error('There was an error with the function storeLog', err);
    } finally {
      console.log('The log has been recorded successfully');
    }

    // SENDING THE PASSWORD RESET EMAIL.
    try {
      this.auth.sendPasswordResetEmail(user.email);
    } catch (err) {
      console.error('There was a problem with sendPasswordResetEmail function', err);
      return err;
    } finally {
      console.log('The password reset email has been sent successfully');
    }

    user.uid = createdUser.uid;
    this.uiMessaging_$.toastMessage(`${user.role} created. Please check your ${user.role}'s list to verify.`, 'INFO');
    // this.writeUserToDB(user, 'N/A', createdBy, () => this.auth.updateCurrentUser(this.auth.currentUser));
    this.errorAuth.next('');
  }

  determineRoute(role: any) {
    this.ng.run(() => this.router.navigateByUrl('/')).then(() => location.reload());
  }

  async updateConsultantUser(email, ownerID) {
    const querySnapshot = await dbUsers.where('email', '==', email).limit(1).get();
    const doc = querySnapshot.docs[0];
    return await dbUsers
      .doc(doc.id)
      .update({
        owners: firebase.firestore.FieldValue.arrayUnion(ownerID),
      })
      .then(() => {
        this.uiMessaging_$.toastMessage('Consultant user updated', null);
      });
  }

  firePatientCantAdd(message = 'Please fill in the required fields') {
    this.errorAuth.next(message);
  }

  async get2FAInfo(email) {
    let twoFAInfo;
    await dbUsers
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(r => {
        const data = r.docs[0].data();
        twoFAInfo = {
          twoFactorAuthenticationSet: data['twoFactorAuthenticationSet'],
          twoFactorEnabled: data['twoFactorEnabled'],
          twoFactorMethod: data['twoFactorMethod'],
          twofactorCode: data['twofactorCode'],
        };
      })
      .catch(err => console.error(err));
    return twoFAInfo;
  }

  getUid() {
    const user: firebase.User = this.auth.currentUser;
    if (user === null || user === undefined) {
      return '';
    }
    return this.auth.currentUser.uid;
  }

  _getUserByUID(uid: string, origin?: string) {
    if (origin) {
      console.log(`Getting user by UID from ${origin}`);
    }
    if (!uid) {
      console.error('uid is null or undefined');
      return Promise.reject('uid is null or undefined');
    }
    return dbUsers
      .where('uid', '==', uid)
      .limit(1)
      .get()
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  async getOwners(uid: string): Promise<string[]> {
    const user = await this._getUserByUID(uid, 'getOwners').catch(err => {
      console.error(err);
      throw err;
    });
    return user.docs[0].data().owners;
  }

  async getOwnerID(uid: string): Promise<string> {
    if (this.isNullOrUndefined(uid)) {
      console.log('uid is null or undefined');
      console.error('uid is null or undefined');
      return '';
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        return this._getUserByUID(uid, 'getOwnerID')
          .then(querySnapshot => {
            return querySnapshot.docs[0]?.data().ownerID || uid;
          })
          .catch(err => {
            console.error(err);
            throw err;
          });
      } else {
        console.log('User is not logged in');
      }
    });
  }

  async getUserUIDByEmail(email) {
    return (await dbUsers.where('email', '==', email).get()).docs[0].data().uid;
  }

  async getOwnerIDByEmail(email: string): Promise<string> {
    const uid = await this.getUserUIDByEmail(email);
    console.log('uid: ', uid);
    if (this.isNullOrUndefined(uid)) {
      console.log('uid is null or undefined');
      console.error('uid is null or undefined');
      return '';
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        return this._getUserByUID(uid, 'getOwnerIDByEmail')
          .then(querySnapshot => {
            return querySnapshot.docs[0]?.data().ownerID || uid;
          })
          .catch(err => {
            console.error(err);
            throw err;
          });
      } else {
        console.log('User is not logged in');
      }
    });
  }

  async getUserRole(uid: string): Promise<string> {
    return this._getUserByUID(uid, 'getUserRole')
      .then(querySnapshot => querySnapshot.docs[0].data().role)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  async getUserByUID(uid: string) {
    return this._getUserByUID(uid, 'getUserByUID')
      .then(querySnapshot => querySnapshot.docs[0].data())
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  private isNullOrUndefined(el) {
    return el == null || el === 'undefined';
  }

  async login(email: string, password: string) {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        if (!result) {
          return;
        }
        if (result['user']) {
          this.sessionStorage_$.setSignedIn(true);
          this.userReady(result['user'], 'login');
        }
      })
      .catch(err => {
        this.errorAuth.next(err.message);
        console.log('Error at login', err);
      });
  }

  isSignedIn() {
    return this.sessionStorage_$.getSignedIn() === 'true';
  }

  showLoader(message?: string, loaderId?: string) {
    if (this.loader && this.loader.getState() === MatDialogState.OPEN) {
      (<LoaderComponent>this.loader.componentInstance).data.message = message;
      if (window.location.hostname !== 'app.nuagedx.com') {
        console.log('Sending nothing to Raygun:');
      } else {
        const rg4js = require('raygun4js');
        rg4js('send', { loaderComponentInstanceData: (<LoaderComponent>this.loader.componentInstance).data });
      }
    } else if (this.loader && this.loader.id === 'loader' && this.loader.componentInstance) {
      (<LoaderComponent>this.loader.componentInstance).data.message = message;
      if (window.location.hostname !== 'app.nuagedx.com') {
        console.log('Sending nothing to Raygun:');
      } else {
        const rg4js = require('raygun4js');
        rg4js('send', { loaderComponentInstanceData: (<LoaderComponent>this.loader.componentInstance).data });
      }
    } else {
      const config: MatDialogConfig = {
        height: 'auto',
        width: 'auto',
        closeOnNavigation: true,
        disableClose: false,
        id: loaderId || 'loader',
        data: { message },
      };

      this.loader = this.dialog_$.open(LoaderComponent, config);
    }
  }

  hideLoader(loaderId?: string) {
    if (this.loader)
      this.ng.run(() => {
        if (!loaderId) {
          this.loader.close();
          this.loader = null;
          return;
        }
        if (this.loader.id === loaderId) {
          this.loader.close();
          this.loader = null;
          return;
        }
      });
  }

  showLoaderV2(message?: string, config?) {
    if (this.loader && this.loader?.getState() === MatDialogState.OPEN) {
      console.log('condition 1');
      (<LoaderComponent>this.loader.componentInstance).data.message = message;
    } else if (this.loader && this.loader.id === 'loader') {
      console.log('condition 2');
      (<LoaderComponent>this.loader.componentInstance).data.message = message;
    } else {
      const dialogConfig: MatDialogConfig = {
        height: 'auto',
        width: 'auto',
        closeOnNavigation: true,
        disableClose: false,
        id: config ? config.loaderId : 'loader',
        hasBackdrop: config ? config.hasBackdrop : true,
        position: { top: '20px' },
        data: { message },
      };
      this.loader = this.dialog_$.open(LoaderComponent, dialogConfig);
    }
  }

  updateLoaderMessage(message: string, loaderId: string) {
    if (this.loader) {
      if (this.loader?.getState() === MatDialogState.OPEN) {
        (<LoaderComponent>this.loader.componentInstance).data.message = message;
        (<LoaderComponent>this.loader.componentInstance).data.icon = 'check';
        (<LoaderComponent>this.loader.componentInstance).ngOnInit();
        console.log('Message updated in open state');
      } else if (this.loader.id === loaderId) {
        (<LoaderComponent>this.loader.componentInstance).data.message = message;
        (<LoaderComponent>this.loader.componentInstance).data.icon = 'check';
        (<LoaderComponent>this.loader.componentInstance).ngOnInit();
        console.log('Message updated with matching id');
      } else {
        console.log('Loader state is not open and id does not match');
      }
    } else {
      console.log('Loader is not defined');
    }
  }

  showProgressLoader(
    message?: string,
    options?: {
      position: string;
      fileId: string;
    },
  ) {
    if (this.loader && this.loader?.getState() === MatDialogState.OPEN)
      (<ProgressLoaderComponent>this.loader.componentInstance).data.message = message;
    else if (this.loader && this.loader.id === 'loader')
      (<ProgressLoaderComponent>this.loader.componentInstance).data.message = message;
    else {
      let config: MatDialogConfig = {
        height: 'auto',
        width: '400px',
        position: { top: '50px' },
        closeOnNavigation: false,
        disableClose: false,
        hasBackdrop: false,
        autoFocus: false,
        id: options['fileId'] || 'generate-disc-progress-loader',
        data: { message },
      };

      this.loader = this.dialog_$.open(ProgressLoaderComponent, config);
    }
  }

  createCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  private getRandomState() {
    return Math.random()
      .toString(36)
      .split('')
      .map(x => x.charCodeAt(0).toString(16))
      .join('');
  }

  handleSSOClioLogin(params) {
    const { sso } = params;
    this.sessionStorage_$.setSSO(sso);
    const randomState = this.getRandomState();
    this.createCookie(`sso_state`, randomState, 1 / 24 / 60);
    this.authRedirectToClioAuthorizeV2(randomState);
  }

  private createURLWithParams(paramsObj: { redirect_uri: string; scope: string; state: string; client_id: string }): HttpParams {
    const { redirect_uri, scope, state, client_id } = paramsObj;
    let params = new HttpParams().set('response_type', 'code');

    if (client_id) {
      params = params.set('client_id', client_id);
    }

    if (redirect_uri) {
      params = params.set('redirect_uri', redirect_uri);
    } else {
      params = params.set('redirect_url', environment.config.clio.redirectsGroup.clientProfile);
    }

    if (scope) {
      params = params.set('scope', scope);
    }

    if (state) {
      params = params.set('state', state);
    }

    return params;
  }

  private authRedirectToClioAuthorizeV2(randomState) {
    console.log('authRedirectToClioAuthorizeV2: ');
    const { SSO } = environment.config.clio;
    // FIXME: Has to use another variable for the EU version.
    const eu = this.sessionStorage_$.getAddToClioEU() || this.userData.getValue()['clioRegion'] === 'eu' || false;
    const paramsObj = {
      response_type: 'code',
      client_id: SSO.key,
      redirect_uri: eu ? SSO.callback_eu : SSO.callback,
      scope: 'openid',
      state: randomState,
    };

    const params = this.createURLWithParams(paramsObj);
    const request = new HttpRequest('GET', SSO.authVersionURL, null, { params });
    console.log('request.urlWithParams: ', request.urlWithParams);
    window.location.href = request.urlWithParams;
  }

  logout(message?: string, eu?: boolean, addtoclio?: boolean) {
    this.drawer?.close();
    if (this.sessionStorage_$.getAddToClioStarted() === 'true')
      return this.auth.signOut().then(() => {
        this.afterSignOut(message, eu, addtoclio);
        this.sessionStorage_$?.setAddToClioStarted(true);
      });
    else return this.auth.signOut().then(() => this.afterSignOut(message));
  }

  observeUser() {
    this.auth.onAuthStateChanged(user => {
      try {
        this.uid = user.uid;
        this.currentUser.next(user);
      } catch (e) {}
    });
  }

  private generateCode() {
    return this.utils_$.getRandomString_(25);
  }

  passwordForgetEmail(email: string) {
    const code = this.generateCode();
    const setPasswordRequest = this.utils_$.setPasswordResetRequest(this.userData.getValue()['id'], {
      code: code,
      type: 'changepassword',
      useremail: email,
    });
    const title = 'You have requested to change your password';
    const signature = this.getSignature();
    const message = this.getPasswordForgetEmailMessage(code);
    const body = this.getBody(title, message, signature);
    const subject = 'Change your password';

    return setPasswordRequest.toPromise().then(async () => {
      let targetEmail;
      // If localhost the target email address is the one in the environment file.
      targetEmail = window.location.hostname === 'localhost' ? environment.config.emailAddress : email;

      await this.utils_$.simpleEmail(body, [targetEmail], subject);
      this.uiMessaging_$.toastMessage(
        'An email has been sent to your email address. Please check your inbox.',
        'EMAIL SENT',
      );
    });
  }

  async sendVerificationCodeEmail(email, code) {
    await this.setUserCode(code, 'email');
    const url = `${environment.constants.cloudfunctionsURL}email-simpleMail`;
    const dest = email;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body =
      `<h2>Please confirm</h2>` +
      `<p>Use the following code to confirm your Two-factor Authentication (2FA):</p>` +
      `<strong>${code}</strong>` +
      `<p>There is a prompt input where you have to paste/type this code.</p>`;
    const subject = `Please confirm your email address`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          console.log('RES:', res);
        }
      },
      complete: () => console.log('Completed'),
      error: err => console.error(err),
    });
  }

  async setPlan(newplan: string) {
    const id = (
      await this._getUserByUID(this.uid, 'setPlan').catch(err => {
        console.error(err);
        throw err;
      })
    ).docs[0].id;
    dbUsers
      .doc(id)
      .update({ plancode: newplan, plan: firebase.firestore().doc('plans/' + newplan) })
      .then(() => {
        this.uiMessaging_$.toastMessage('Plan changed successfully', null);
      })
      .catch(err => {
        this.uiMessaging_$.toastMessage(err.message, null);
      });
  }

  setNewPasswordExpirationDate(userID) {
    const url = `${environment.constants.cloudfunctionsURL}password-setPasswordExpirationDate`;
    this.http
      .post(url, { docid: userID })
      .toPromise()
      .then(updatePasswordStatus => {
        console.log('updatePasswordStatus :', updatePasswordStatus);
      });
  }

  async sendVerificationCode(code: number, email: string) {
    let get2faInfo;
    try {
      // NOTE: What is this for?
      get2faInfo = await this.get2FAInfo(email);
      console.log('get2faInfo :', get2faInfo);
    } catch (err) {
      console.error(err);
    }

    try {
      await this.sendVerificationCodeEmail(email, code);
    } catch (err) {
      console.error(err);
    }
  }

  async setUserCode(code, type) {
    const userId = (
      await this._getUserByUID(this.uid, 'setUserCode').catch(err => {
        console.error(err);
        throw err;
      })
    ).docs[0].id;
    return dbUsers.doc(userId).update({ twofactorCode: JSON.stringify({ code: code, type: type }) });
  }

  updateUserPassword(newPassword: string) {
    const user = firebase.auth().currentUser;
    return user
      .updatePassword(newPassword)
      .then(() => this.setNewPasswordExpirationDate(this.userData.getValue()['id']))
      .catch(error => console.log('updateUserPassword error', error));
  }

  async updateRecurlyPlan(plan) {
    const planRef = dbPlans.doc(plan);
    const docId = (
      await this._getUserByUID(this.uid, 'updateRecurlyPlan').catch(err => {
        console.error(err);
        throw err;
      })
    ).docs[0].id;
    return dbUsers.doc(docId).update({ plan: planRef, plancode: plan });
  }

  validatePermission(permission: string): boolean {
    const permissionsData = this.userData.getValue()['permissions'];
    if (!permissionsData) {
      return false;
    }
    return permissionsData
      .filter(p => p.access === true)
      .filter(p => p.role === this.userData['role'])
      .find(p => p.object === permission).access;
  }

  getAllPermissions() {
    return this.userData.getValue()['permissions'];
  }

  async loadPermissions() {
    try {
      this.permissions = (await firebase.firestore().collection('permissions').get()).docs.map(doc => doc.data());
    } catch (e) {
      console.error(e);
    }
  }

  async deleteUserSharedFile(fileId: string, email: string) {
    console.log('deleteUserSharedFile: ');
    return firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then(async res => {
        const newFilesSharedWith = res.docs[0]
          .data()
          .filesSharedWith.filter((file: { fileId: string }) => file.fileId !== fileId);
        await dbUsers.doc(res.docs[0].id).update({ filesSharedWith: newFilesSharedWith });
      })
      .catch(err => console.error(err));
  }

  async validateFileExistence(fileId: string) {
    console.log('validateFileExistence: ');
    return firebase.firestore().collection('files').where('fileId', '==', fileId).get();
  }

  async userReady(user, origin?: string) {
    if (this.checkEmptyUser(user) === false) {
      return;
    }

    this.showLoader('Loading user data...');
    this.uid = user.uid;

    const userData = await this.getUserData(user);

    if (this.checkEmptyUserData(userData) === false) {
      return;
    }

    // this.checkClioAccessToken(userData, user);

    // Clean up inactive shared files.
    await this.cleanUpSharedFiles(userData, user.email);

    if (this.checkEmptyUserDataV2(userData) === false) {
      return;
    }

    this.userIsSignedIn.next(true);
    this.sessionStorage_$.setSignedIn(true);

    let _userData = userData.docs.map(doc => doc.data())[0];

    // Check and delete orphaned shared files.
    // _userData = await this.checkDeleteUserOrphanedSharedFiles(
    //   userData.docs.map(doc => doc.data())[0],
    //   user.email,
    // );

    let userObj = this.getUserObject(userData.docs[0].id, user, _userData, await this.getUserIp());

    // Update userObj with the permissions.
    await this.loadPermissions().then(() => (userObj = { ...userObj, permissions: this.permissions }));

    this.userData.next(userObj);

    if (this.userData.getValue()['lpm'] === 'clio') {
      this.checkClioAccessToken(userData, user);
    }

    if (window.location.hostname === 'app.nuagedx.com') {
      const rg4js = require('raygun4js');
      rg4js('setUser', {
        identifier: userObj.email,
        isAnonymous: false,
        email: userObj.email,
        firstName: userObj['name'],
        fullName: userObj['name'],
        identifierType: 'email',
      });

      rg4js('setTags', [userObj['role'], userObj['email']]);
    }

    this.clioValidations();

    // Then, in your original method:
    if (this.dialog_$?.dialog) {
      this.closeAccessDeniedDialogs();
    }

    if (this.userData.getValue()['clioregistered']) {
      this.ng.run(() => this.drawer?.open());
    }

    this.getNotifications();

    this.hideLoader();
    return userObj;
  }

  private filterFilesSharedWith(_fileId: string, filesSharedWith: any[]) {
    console.log('filesSharedWith: ', filesSharedWith);
    return filesSharedWith.filter(({ fileId }) => fileId !== _fileId);
  }

  async getFileSharedUsers(fileId: string): Promise<string[]> {
    return (await firebase.firestore().collection('files').where('fileId', '==', fileId).limit(1).get()).docs[0].data()[
      'sharedUsers'
    ];
  }

  private async checkDeleteUserOrphanedSharedFiles(userData: any, email: string) {
    let __userData = userData;
    if (userData?.filesSharedWith?.length > 0) {
      // Validate userSharedFiles.
      // Sometimes, the userSharedFiles are not deleted when the unshare process is completed.
      console.log('userData.filesSharedWith: ', userData.filesSharedWith);
      for (const file of userData.filesSharedWith) {
        if (await this.validateFileExistence(file.fileId)) {
          // Delete the file from the user's shared files.
          this.deleteUserSharedFile(file.fileId, email);

          // Delete the file from the userData.
          // this.userData.getValue()['filesSharedWith'] = this.filterFilesSharedWith(
          //   file.fileId,
          //   this.userData.getValue()['filesSharedWith'],
          // );

          // Delete the file from the userData.
          __userData.filesSharedWith = this.filterFilesSharedWith(file.fileId, __userData.filesSharedWith);
        } else {
          // Sometimes the files shared inside the userData has no reference to this one from the files itself.
          if ((await this.getFileSharedUsers(file.fileId)).length) {
            await this.updateFileSharedUsers(file.fileId, email, userData.name)
              .catch(err => {
                console.error('Error updating file shared users', err);
              })
              .then(() => {
                console.log('File shared users updated');
              });
          }
        }
      }
    }
    return __userData;
  }

  private updateFileSharedUsers(fileId: string, email: string, name: string) {
    console.log('updateFileSharedUsers: ');
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', fileId)
      .limit(1)
      .get()
      .then(async res => {
        const file = res.docs[0].data();
        const newSharedUsers = [...file.sharedUsers, { email, name }];
        console.log('newSharedUsers: ', newSharedUsers);
        return firebase.firestore().collection('files').doc(res.docs[0].id).update({ sharedUsers: newSharedUsers });
      });
  }

  private closeAccessDeniedDialogs() {
    this.dialog_$?.dialog.openDialogs.forEach(dialog => {
      if (dialog.id === 'access-denied') {
        dialog.close();
      }
    });
  }

  private async handleGetAuthorizationCodeV3Result(result: firebase.functions.HttpsCallableResult, user: any) {
    console.log('handleGetAuthorizationCodeV3Result: ');
    if (result['data'] && Object.keys(result['data']).length > 0) {
      console.log('Clio SSO Access Token Updated');
      console.log('AuthService > handleGetAuthorizationCodeV3Result');

      if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
        await this.updateClioCustomActions(user.uid, 'auth.service:661 > handleGetAuthorizationCodeV3Result')
          .then(customactionsUpdated => console.log('Clio custom actions updated', customactionsUpdated))
          .catch(err => {
            if (environment.config.raygun) {
              if (window.location.hostname !== 'app.nuagedx.com') {
                console.log('Error on localhost:', err);
              } else {
                const rg4js = require('raygun4js');
                rg4js('send', {
                  error: err,
                  customData: { user: this.userData.getValue() },
                  tags: ['Clio custom actions update error'],
                });
              }
            }
            console.log('Clio custom actions update error', err);
          });
      } else console.log('Add to Clio process started');
    }
  }

  getUserSettings(useruid: string) {
    return this._getUserByUID(useruid, 'getUserSettings')
      .then(res => res.docs[0].data().settings)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  async handleClioTokenRenew(userObj) {
    if (userObj.clioAccessToken && this.clioTokenRenewRunning === false) {
      const { data } = await this.renewToken(userObj).catch(err => {
        if (err.error.code === 501) {
          this.uiMessaging_$.toastMessage('Clio token not renewed. Please try to get Clio Authorization again.', null);
          this.clioTokenRenewRunning = false;
          return err;
        }
      });
      if (data.status === 200) {
        console.log(data.message);
        this.clioTokenRenewRunning = false;
      } else {
        console.log('Clio token not renewed');
        this.clioTokenRenewRunning = false;
      }
      this.clioTokenRenewRunning = true;
    }
  }

  writeUserToDB(user: User, companyName: string = 'N/A', createdBy: string = 'N/A', action: Function = null) {
    dbUsers
      .add({
        name: user.name,
        email: user.email,
        role: user.role,
        uid: user.uid,
        plancode: user.plancode,
        plan: user.plan,
        companyName: companyName,
        createdBy: createdBy,
        ownerID: user.ownerID,
        owners: [user.ownerID],
        disabled: false,
      })
      .then(document => {
        if (action) {
          action();
        } else {
          this.determineRoute(user.role);
        }
      })
      .catch(error => console.log(error));
  }

  getUserObject(id: string, user, userData: firebase.firestore.DocumentData, userip: string) {
    return {
      clioAccessToken: userData.clioAccessToken,
      clioSSO: userData.clioSSO,
      clioRegion: userData.clioRegion,
      clientMatter: userData.clientMatter || null,
      clioCustomActions: userData.clioCustomActions,
      clioregistered: userData.clioregistered || false,
      email: userData.email,
      filesSharedWith: userData.filesSharedWith,
      id: id,
      lpm: userData.lpm,
      lastSession: userData.lastSession || '',
      ownerID: userData.ownerID,
      owners: userData.owners,
      permissions: [],
      plan: userData.plan,
      phoneNumber: userData.phoneNumber,
      plancode: userData.plancode,
      practicepantherAccessToken: userData.practicepantherAccessToken,
      recurlyaccountcode: userData.recurlyaccountcode,
      role: userData.role,
      realRole: userData.role,
      settings: userData.settings,
      testaccount: userData.testaccount,
      userName: userData.name,
      firstName: userData.firstname || null,
      lastName: userData.lastname || null,
      userEmail: userData.email,
      user: user,
      userDBRole: userData.role,
      uid: userData.uid,
      userip: userip,
    };
  }

  async updateClioCustomActions(uid, origin?: string) {
    const eu = this.sessionStorage_$.getAddToClioEU() || this.userData.getValue()['clioRegion'] === 'eu' || false;
    if (origin) console.log('origin: ', origin);
    return firebase.functions().httpsCallable('clio-updateClioCustomActionsV2')({ uid: uid, eu: eu });
  }

  async getUserData(user): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
    return this._getUserByUID(user.uid, 'getUserData').catch(err => {
      console.error(err);
      throw err;
    });
  }

  async getUserDataByUID(uid) {
    return this._getUserByUID(uid, 'getUserDataByUID').catch(err => {
      console.error(err);
      throw err;
    });
  }

  async getUserIp(): Promise<string> {
    const result = await this.http
      .get('https://jsonip.com')
      .toPromise()
      .catch(err => err);
    return result['ip'] || '';
  }

  renewToken(userObj) {
    return firebase.functions().httpsCallable('clio-renewToken')({ userdocid: userObj.id });
  }

  signInWithCustomToken(token) {
    return firebase.auth().signInWithCustomToken(token);
  }

  setLastSession(sessionObj, userdocid) {
    return firebase
      .firestore()
      .collection('users')
      .doc(userdocid)
      .update(sessionObj)
      .catch(err => console.error(err));
  }

  updateUserClioRegistered(newvalue, plan?: string) {
    console.log('userid :', this.userData.getValue()['id']);
    let updateData = { clioregistered: newvalue, plancode: null, plan: null };
    if (plan) updateData = { ...updateData, plancode: plan, plan: firebase.firestore().doc('plans/' + plan) };
    return dbUsers
      .doc(this.userData.getValue()['id'])
      .update(updateData)
      .catch(err => console.error(err));
  }

  async updateUserData() {
    return this.userReady((await dbUsers.doc(this.userData.getValue()['id']).get()).data(), 'updateUserData');
  }

  async getFreshFirestoreUserData() {
    return this.userReady(
      (await dbUsers.doc(this.userData.getValue()['id']).get()).data(),
      'getFreshFirestoreUserData',
    );
  }

  private async existingClioAccessToken(user) {
    if (this.sessionStorage_$.getAddToClioStarted() !== 'true')
      await this.updateClioCustomActions(user.uid, 'auth.service:661 > userReady')
        .then(result => console.log('Clio custom actions updated', result))
        .catch(err => console.log('Clio custom actions update error', err));
    else console.log('Add to Clio process started');
  }

  private async noClioAccessToken(userData, user) {
    console.log('Clio Access Token not found');
    console.log('It is impossible to update Clio Custom Actions');

    const eu = this.sessionStorage_$.getAddToClioEU();

    // Check if it comes from Clio Authorization.
    if (this.activatedRoute.snapshot.queryParams.code) {
      const currentUrl = `https://${environment.config.site_uri}` + this.router.url.split('?')[0];

      const params = {
        uid: user.uid,
        code: this.activatedRoute.snapshot.queryParams.code,
        redirect_uri: currentUrl,
        eu: eu ? 1 : 0,
        updateCustomActions: 1,
      };

      if (this.sessionStorage_$.getClioGetAuthorizationCodeV4Executed() !== 'true') {
        await this.clioGetOAuthToken(params)
          .then(async result => {
            this.sessionStorage_$.setClioGetAuthorizationCodeV4Executed('true');
            await this.handleGetAuthorizationCodeV3Result(result, user);

            // Complete Add To Clio process.
            if (this.sessionStorage_$?.getAddToClioStarted() === 'true') {
              const { appIntegrationsCallBackURL, appIntegrationsCallBackURL_eu } = environment.config.clio.SSO;
              const eu = this.sessionStorage_$.getAddToClioEU();
              const appIntegrationCallbackURL = eu ? appIntegrationsCallBackURL_eu : appIntegrationsCallBackURL;
              window.location.href = appIntegrationCallbackURL;
            }
          })
          .catch(err => console.log('clio-getAuthorizationCodeV3', err));
      }
    }
  }

  async handleGetAuthorizationCodeV3ResultV2(result: any, user: any) {
    if (result['data'] && Object.keys(result['data']).length > 0) {
      this.uiMessaging_$.toastMessage('Clio Access Token Updated', null);

      console.log('AuthService > handleGetAuthorizationCodeV3Result');

      if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
        await this.updateClioCustomActions(user.uid, 'auth.service:661 > handleGetAuthorizationCodeV3Result')
          .then(customactionsUpdated => {
            console.log('Clio custom actions updated', customactionsUpdated);
          })
          .catch(err => console.log('Clio custom actions update error', err));
      } else {
        console.log('Add to Clio process started');
      }
    }
  }

  private afterSignOut(message, eu?: boolean, addtoclio?: boolean) {
    this.hideLoader();
    this.sessionStorage_$.cleanAll();
    this.localStorage_$.cleanAll();

    this.ng.run(() => {
      // Clean browser history.
      location.replace('/login');
      console.log('>>');
      if (message) this.snackBar.open(message, 'Close', { duration: 3000 });
    });
  }

  async getNotifications(fileId?: string) {
    const notifications = await this.notifications_$.getNotificationsByUserId(this.uid);

    if (this.currentPatient?.caseName) {
      this.handleDownloadNotifications(notifications, this.currentPatient.caseName, fileId);
    }

    setTimeout(() => {
      this.getNotifications(fileId);
    }, environment.config.getNotificationsFrequency);

    return this.updateUserDataNotifications(notifications);
  }

  private updateUserDataNotifications(notifications: firebase.firestore.DocumentData[]) {
    this.userData.next({ ...this.userData.getValue(), notifications });
  }

  hasNotifications() {
    if (!this.userData.getValue()['notifications']) return false;
    return this.userData.getValue()['notifications'] && this.userData.getValue()['notifications'].length > 0;
  }

  private checkEmptyUserData(userData) {
    if (userData.docs.length === 0) {
      console.log(' User ready: user data is empty');
      this.logout();
      return false;
    } else return true;
  }

  private async cleanUpSharedFiles(userData, userEmail) {
    if (origin === 'login' && userData.docs[0].data().role === UserRoles.consultant) {
      await firebase
        .functions()
        .httpsCallable('files-cleanSharedButInactiveFiles')({ email: userEmail })
        .then(result => console.log('cleanSharedButInactiveFiles', result))
        .catch(err => console.log('cleanSharedButInactiveFiles', err));
    }
  }

  private checkEmptyUserDataV2(userData) {
    if (userData.empty) {
      console.log('User not found');
      this.logout();
      this.hideLoader();
      return false;
    } else return true;
  }

  private checkEmptyUser(user) {
    if (!user || Object.keys(user).length === 0) {
      console.log(' User ready: user is empty');
      return false;
    } else return true;
  }

  private async checkClioAccessToken(userData, user) {
    const { clioAccessToken } = userData.docs[0].data();

    if (clioAccessToken !== undefined && JSON.parse(clioAccessToken)['access_token']) {
      await this.existingClioAccessToken(user);
    } else {
      this.noClioAccessToken(userData, user);
    }
  }

  private clioValidations() {
    if (this.userData.value['lpm'] === 'clio') {
      const isNotClioRegistered = this.isClioRegistered() === false;
      const isNotClioSSO = !this.userData.value['clioSSO'];
      const isNotTestAccount = !this.userData.value['testaccount'];
      const isNotAddToClioStarted = !Boolean(this.sessionStorage_$.getAddToClioStarted());
      const notClioFlow = isNotClioSSO || isNotAddToClioStarted;
      const hasFPPlan = this.userData.getValue()['plancode'] === DEFAULT_PLAN;

      if (isNotClioRegistered && notClioFlow && isNotTestAccount && hasFPPlan) {
        this.redirectiron_$.redirectToCompleteClioRegistration();
      }
    }
  }

  private getTargetEmails(myemail: string, email: string) {
    return environment.config.host === 'http://localhost:4200/' ? [myemail] : [myemail].concat([email]);
  }

  getClioAccessToken() {
    const params = { userdocid: this.userData.getValue()['id'] };
    return firebase.functions().httpsCallable('clio-getClioAccessToken')(params);
  }

  doesOwnerHasAccess() {
    if (this.checkIfCurrentUserIsOwner()) {
      return Promise.resolve(this.userData.getValue()['plancode'] === 'platinum');
    } else {
      return this.checkOwnerAccess();
    }
  }

  private async checkOwnerAccess(): Promise<boolean> {
    const ownerUID = this.userData.getValue()['ownerID'];
    const ownerData = await this.getOwnerData(ownerUID);
    return ownerData['plancode'] === 'platinum';
  }

  private checkIfCurrentUserIsOwner(): boolean {
    return this.userData.getValue()['role'] === UserRoles.owner;
  }

  async getOwnerData(ownerUID): Promise<firebase.firestore.DocumentData> {
    return this._getUserByUID(ownerUID, `getOwnerData`)
      .then(querySnapshot => querySnapshot.docs[0].data())
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  isNotSilverPlan() {
    return this.userData.getValue()['plancode'] !== 'silver';
  }

  getPlanLimits(codeplan) {
    codeplan = codeplan === 'fp' ? 'free' : codeplan;
    return dbPlans
      .doc(codeplan)
      .get()
      .then(res => res.data()['limits']);
  }

  async getUsersCount() {
    const uid = this.userData.getValue()['uid'];
    console.log('uid :', uid);
    const usersData = await dbUsers
      .where('owners', 'array-contains', uid)
      .where('disabled', '==', false)
      .get()
      .then(res => res.docs.map(doc => doc.data()));
    const groups = ['admin', 'owner', 'associate', 'consultant'];
    const resultGroups = {
      admin: [],
      owner: [],
      associate: [],
      consultant: [],
    };
    usersData.forEach(user => {
      const role = this.roleToString(user['role']);
      const index = groups.indexOf(role);
      if (index !== -1) resultGroups[role].push(user);
    });
    return resultGroups;
  }

  roleToString(role: string) {
    switch (role) {
      case 'Associate':
        return 'associate';
      case 'Client Admin':
      case 'Admin':
        return 'admin';
      case 'Consultant':
        return 'consultant';
      case 'Client':
        return 'client';
      default:
        return '';
    }
  }

  cleanBlankSpaces(str: string) {
    return str.replace(/[^(A-Za-z0-9)]/g, '-');
  }

  private async handleDownloadNotifications(
    notifications: firebase.firestore.DocumentData[],
    caseName: any,
    fileId: string,
  ) {
    const zippedDiscsNotifications = this.getNewDownloadNotifications(notifications).filter(n => n.caseId === caseName);

    if (zippedDiscsNotifications.length === 0) return;

    // console.log('zippedDiscsNotifications :', zippedDiscsNotifications);

    const lastNotification = this.getLastNotification(zippedDiscsNotifications);

    if (fileId || lastNotification) fileId = !fileId ? lastNotification['fileId'] : fileId;

    if (this.userData.getValue()['role'] === UserRoles.owner) {
      const newDownloadNotificationsCount = zippedDiscsNotifications.length;
      // if (newDownloadNotificationsCount > 0 && this.currentDownloadIsSelected(zippedDiscsNotifications)) {
      //   this.runGeneratedDiscFirstStepReady(fileId);
      //   await this.removeNewDownloadNotifications(fileId);
      // } else {
      //   console.log('No new download notifications');
      // }

      if (newDownloadNotificationsCount > 0) {
        /**
         * NOTE: Comunicate with the client profile component and say which
         * studies are ready to be downloaded.
         */

        if (fileId) {
          this.clientProfileFiles.next(
            zippedDiscsNotifications.map(n => ({ fileId: n.fileId, originalFileId: n.originalFileId, waiting: false })),
          );

          console.log('>> lastNotification: ', lastNotification);
          const { fileId, fileName, fileDesc, originalFileId } = lastNotification;
          this.handleGeneratedDiscCheck({ fileId, fileName, fileDesc, originalFileId });
        }
      }
    }
  }

  private removeNewDownloadNotifications(fileId: string) {
    return firebase
      .functions()
      .httpsCallable('notifications-removeNewDownloadNotifications')({ fileId })
      .then(result => console.log('notifications-removeNewDownloadNotifications', result))
      .catch(err => console.log('notifications-removeNewDownloadNotifications', err));
  }

  private currentDownloadIsSelected(notifications) {
    if (this.clientProfileSelectedFiles.length === 0) {
      console.log('No file(s) selected');
      return false;
    }
    if (notifications) {
      const selectedFile = this.clientProfileSelectedFiles[0];
      if (!selectedFile) {
        console.log('No file(s) selected');
        return false;
      }
      if (notifications.some(n => n.fileId === 'zippedDisc_' + selectedFile.fileId)) {
        console.log('File selected');
        return true;
      } else {
        console.log('Selected file', selectedFile.fileId);
        console.log('File not selected');
        return false;
      }
    } else {
      console.log('No notifications');
      return false;
    }
  }

  private runGeneratedDiscFirstStepReady(fileId: string) {
    this.clientProfileActions.next('generateddiscfirststepready');
    this.clientProfileActionsData = { fileId };
  }

  private getLastNotification(notifications: firebase.firestore.DocumentData[]) {
    // console.log('*** getLastNotification ***');
    // console.log('notifications: ', notifications);
    const noModalOffNotifications = notifications.filter(n => !n.modalOff);
    // console.log('noModalOffNotifications: ', noModalOffNotifications);
    // console.log('***');

    const sortedNotifications = noModalOffNotifications.sort((a, b) => {
      const aDate = new Date(a.createdAt.seconds * 1000);
      const bDate = new Date(b.createdAt.seconds * 1000);
      return bDate.getTime() - aDate.getTime();
    });

    return sortedNotifications[0];
  }

  disableUserDataNewDownloadNotification(fileId: string) {
    const userDataNotifications = this.userData.getValue()['notifications'];
    // console.log('>>>');
    // console.log('userDataNotifications: ', userDataNotifications);

    userDataNotifications.forEach(n => {
      if (n.fileId === fileId && n.tag === 'new-download') n.modalOff = true;
    });

    // console.log('userDataNotifications: ', userDataNotifications);
    const newUserData = { ...this.userData.getValue(), notifications: userDataNotifications };
    this.userData.next(newUserData);
    // console.log('UserDataNotifications', this.userData.getValue()['notifications']);
    // console.log('<<<');
  }

  disableFirestoreNewDownloadNotification(fileId: string) {
    this.notifications_$.disableNewDownloadNotification(fileId);
  }

  public setCurrentPatient(patient) {
    this.currentPatient = patient;
  }

  public setCurrentFolder(folder) {
    this.currentFolder = folder;
  }

  private getNewDownloadNotifications(notifications: firebase.firestore.DocumentData[]) {
    return notifications.filter(n => n.tag === 'new-download');
  }

  private shouldOpenDialog() {
    return (
      !this.confirmationDialogExists &&
      this.currentFolder !== this.discsFolderName &&
      !this.dialog_$.dialog.openDialogs.find(d => d.id === 'confirmation-dialog') &&
      this.sessionStorage_$.getStoppedGeneratedDiscAlert(this.currentPatient.caseName) !== true
    );
  }

  handleGeneratedDiscCheck(options): boolean {
    // Determine if the dialog should be opened
    if (this.shouldOpenDialog()) {
      const { fileId, fileName, fileDesc } = options;
      const removeZippedDIsc = (fileId: string) => fileId.replace('zippedDisc_', '');
      this.hideProgressLoader(removeZippedDIsc(fileId));

      // Add the notification to the client profile notifications.
      let notifications = this.clientProfileNotifications.getValue();

      const message = (() => {
        const _fileDesc = fileDesc ? `<br><b>Study:</b> ${fileDesc}<br>` : '<br>';
        const fileNameDesc = fileName ? `<br><b>Filename:</b> ${fileName}.${_fileDesc}` : _fileDesc;
        const message = `The disc files have been generated.${fileNameDesc} Do you want to go to the <b>Disc files folder</b>?`;
        return message;
      })();

      const _options = [
        { text: 'Yes', action: 'ok' },
        { text: 'Cancel', action: 'cancel' },
      ];

      if (!notifications.some(n => n.id === fileId)) notifications.push({ message, options: _options, id: fileId });

      this.cleanNotifications(notifications, fileId);
      console.log('notifications: ', notifications);

      this.clientProfileNotifications.next(notifications);
      return true;
    } else {
      console.log('Dialog should not be opened');
      return false;
    }
  }
  hideProgressLoader(loaderId: string) {
    this.dialog_$.close(loaderId);
  }

  private cleanNotifications(notifications, fileId) {
    const str = fileId.indexOf('zippedDisc_') === -1 ? `zippedDisc_${fileId}` : fileId;
    notifications = notifications.filter(n => n.id !== str);
    this.clientProfileNotifications.next(notifications);
  }

  public clioGetOAuthToken({ uid, code, redirect_uri, eu, updateCustomActions }) {
    if (!uid) {
      console.error('No uid has been provided');
      return;
    }

    if (!code) console.error('No code has been provided');
    if (!redirect_uri) console.error('No code has been provided');

    const params = { uid, code, redirect_uri, eu, updateCustomActions };
    return firebase.functions().httpsCallable('clio-getOAuthToken')(params);
  }

  public getUserRoleV2() {
    return this.userData.getValue()['role'];
  }

  public getOwnerPlan(ownerID) {
    if (!ownerID) {
      console.log('getOwnerPlan ::: Owner ID not found');
      return;
    }
    return dbUsers
      .doc(ownerID)
      .get()
      .then(res => res.data().plancode)
      .catch(error => {
        console.error('Error getting owner plan:', error);
        throw error;
      });
  }
  getBody(title, message, signature) {
    return `<h2 style="font-size: 20px; color: #333;">${title}</h2>
${message}
${signature}`;
  }

  getPasswordForgetEmailMessage(code) {
    return `<p style="font-size: 16px; color: #333;">Please use the following link to change it: <a href='${environment.config.host}/resetpassword?code=${code}' style="color: #1a0dab;">Click here to reset the password</a>
There is a prompt input where you have to enter your new password.

The password reset link is valid for 60 minutes. After that, you will need to request a new one by clicking <a href="https://${environment.config.site_uri}/login" style="color: #1a0dab;">here</a> and selecting the "reset password" option.

If you haven't requested this, please ignore this email.


Thank you</p>`;
  }

  getSignature() {
    return `<table style="height: 112px; width: 282px; border: 0; font-family: Arial, sans-serif; color: #333;">
  <tbody>
    <tr>
      <td>
        <img id="a7f924e6-1cb4-46f2-aaa3-825b5d06c116" style="width:88px; height:84px" src="https://nuagedx.com/wp-content/uploads/2023/10/nuagedx-logo-signature.jpg" alt="NuageDx Logo" width="88" height="84" />
      </td>
      <td style="text-align: left; vertical-align: middle;">
        <div style="font-size: 16pt;">
          <strong>NuageDx Team</strong>
          (707) 584-6405
          <a href="mailto:webmaster@nuagedx.com" style="color: #1a0dab;">webmaster@nuagedx.com</a>
        </div>
        <div><a href="https://nuagedx.com" style="color: #1a0dab;">www.nuagedx.com</a></div>
      </td>
    </tr>
  </tbody>
</table>`;
  }

  checkClioNotRegistered(): boolean {
    const isLpmClio = this.userData.getValue()['lpm'] === 'clio';

    if (!isLpmClio) {
      return true;
    }

    if (!this.isClioRegistered()) {
      this.redirectToCompleteClioRegistration();
      return;
    }

    return true;
  }

  redirectToCompleteClioRegistration() {
    this.router.navigate(['/', 'completeclioregistration']);
  }

  isLpmClio() {
    return this.userData.value['lpm'] === 'clio';
  }
}
