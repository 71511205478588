<mat-dialog-content class="dialog-content"
                    fxLayout="row">
  <p class="mat-body"
     fxFlex
     [innerHTML]="message"></p>
  <div style="margin-bottom: 0px"
       fxLayout="row"
       fxLayoutAlign="space-around center"
       fxLayoutGap="10px">
    <button color="warn"
            style="margin-left: 10px"
            mat-flat-button
            (click)="confirm()">
      {{ btnOkText }}
    </button>

    <!-- <button
      *ngIf="options?.markAsRead"
      (click)="markAsRead()"
      mat-flat-button
      color="warn"
      style="margin-left: 10px">
      <mat-icon>check_circle</mat-icon>
      Mark as read.
    </button> -->

    <button color="warn"
            mat-flat-button
            (click)="close()">
      <!-- {{ btnCancelText }} -->
      <mat-icon>
        close
      </mat-icon>
    </button>
  </div>
</mat-dialog-content>
