import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UIMessagingService {
  constructor(private snackBar: MatSnackBar) {}

  toastMessage(message: string, action: string, additionalparams?: MatSnackBarConfig) {
    const config: MatSnackBarConfig = {
      ...environment.config.snackBar,
      ...additionalparams,
      panelClass: ['snack-bar-on-top'],
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
    };
    this.snackBar.open(message, action, config);

    // Ensure the snackbar is always on top
    const snackBarContainer = document.querySelector('.mat-snack-bar-container');
    if (snackBarContainer) {
      (snackBarContainer as HTMLElement).style.setProperty('z-index', '9999', 'important');
    }
  }

  closeToastMessage() {
    this.snackBar.dismiss();
  }
}
