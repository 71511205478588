<mat-toolbar fxLayout
             fxLayoutAlign="space-between center"
             [ngStyle]="{ height: 'auto' }">
  <div fxFlex="72">
    <button *ngIf="deleteButton"
            mat-button
            [disabled]="!allowDeleteNote || selection.selected.length === 0"
            (click)="openDeleteConfirmDialog()">
      <mat-icon>
        delete
      </mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">
        DELETE
      </span>
    </button>
    <button *ngIf="fileSelected && currentFolderName !== discsFolderName"
            id="marginBFileInfo"
            mat-button
            (click)="openAddNoteDialog()">
      <mat-icon>
        note_add
      </mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">
        ADD COMMENT OR ATTACHMENT
      </span>
    </button>
    <button mat-button
            [disabled]="!refreshButton"
            (click)="handleRefresh()">
      <mat-icon>
        refresh
      </mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">
        REFRESH
      </span>
    </button>
    <button *ngIf="permissions_createfolder && currentFolderName === ''"
            mat-button
            (click)="createFolder(currentFolder, { folderName: currentFolderName })">
      <mat-icon>
        create_new_folder
      </mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">
        CREATE FOLDER
      </span>
    </button>

    <!-- FILE ACTIONS MENU -->
    <button #fileActionsMenu

            class="file-actions-menu"
            mat-button

            [matMenuTriggerFor]="menu"
            [ngClass]="{ show: fileActionsEnabled() }">
      <mat-icon>
        description
      </mat-icon>
      &nbsp;
      <span [ngClass]="'mdc-button__label'">
        FILE ACTIONS
      </span>
    </button>
    <mat-menu #menu="matMenu"
              xPosition="before">
      <ng-container *ngFor="let item of fileActionsMenuItems">
        <button *ngIf="item.condition()"
                mat-menu-item
                [class]="item.class"
                (click)="item.action($event)">
          <mat-icon>
            {{item.icon}}
          </mat-icon>
          {{ item.label }}
        </button>
      </ng-container>
    </mat-menu>
    <!-- END FILE ACTIONS MENU -->

    <button *ngIf="
        currentFolder !== '' &&
        selection.selected.length === 0 &&
        files.length > 0 &&
        realRole !== UserRoles.consultant &&
        realRole !== UserRoles.client
      "
            mat-button
            [disabled]="!refreshButton || files.length <= 1"
            (click)="openFileSearch()">
      <mat-icon>
        search
      </mat-icon>
      &nbsp; SEARCH
    </button>

    <button *ngIf="selection.selected.length > 1 && currentFolder !== ''"
            mat-button
            [disabled]="!refreshButton"
            (click)="sortFilesBySelected()">
      <mat-icon>
        fact_check
      </mat-icon>
      &nbsp; SORT FILES BY SELECTED
    </button>

    <button *ngIf="selection.selected.length === 0 && currentFolder !== ''"
            mat-button
            [disabled]="files.length <= 1"
            [matMenuTriggerFor]="sortmenu">
      <mat-icon>
        sort
      </mat-icon>
      &nbsp; SORT FILES BY
    </button>

    <mat-chip *ngIf="filesFilter.name !== ''"
              disableRipple="true"
              (removed)="removeFilter()">
      {{ filesFilter.name }}
      <button aria-label="'remove ' + filesFilter.name"
              matChipRemove>
        <mat-icon>
          cancel
        </mat-icon>
      </button>
    </mat-chip>
    <mat-menu #sortmenu="matMenu">
      <button *ngFor="let sort of sortTypes; index as i"
              mat-menu-item
              [value]="i"
              (click)="handleSelect(i)">
        {{ sort.label }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
