<div *ngIf="permissions_uploadfiles || realRole === 'Client'"
     fxLayout="row"
     fxLayoutAlign="start center">
  <button *ngIf="currentFolderName !== discsFolderName"
          color="warn"
          mat-flat-button
          (click)="openDialog($event)">
    <mat-icon>
      cloud_upload
    </mat-icon>
    Choose new files or discs to upload to your folders
  </button>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <button *ngIf="realRole !== 'Client' && currentFolderName !== discsFolderName"

          color="warn"
          mat-flat-button

          [disabled]="!importFromAppsBtn"
          (click)="showImportFromApps()">
    <mat-icon>
      download
    </mat-icon>
    Import from Apps
  </button>

  <button *ngIf="realRole === UserRoles.owner"

          color="warn"
          style="margin-left: 20px"

          aria-hidden="false"
          aria-label="Add consultant to this case."
          mat-flat-button
          matTooltip="Add consultant to this case."
          (click)="addConsultant()">
    <mat-icon>
      person_add
    </mat-icon>
    Add Consultant
  </button>

  <button *ngIf="clioMatterId"
          style="margin-left: 20px; border: 1px solid #d3d3d3"
          mat-flat-button
          matTooltip="Open this Matter in Clio"
          (click)="backToClio(clioMatterId)">
    <img alt="Clio logo"
         src="./../../../assets/png/clio-blue-square.png"
         width="15px">
    Back to Clio
  </button>
</div>
