import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UploadBlock } from 'src/app/models/upload-block';
import { environment } from 'src/environments/environment';

import { SimpleMessageWindowComponent } from '../components/ui/simple-message-window/simple-message-window.component';
import { DialogService } from '../dialog.service';
import { LogLevelsDictionary } from '../dictionaries/LogLevels';
import { DICOMMetaDataUnit } from '../models/DICOMMetaDataUnit';
import { Folder } from '../models/Folder2';
import { NotificationData } from '../models/NotificationData';
import { NotificationTypes } from '../models/NotificationTypes';
import { NotificationsService } from '../notifications.service';
import { UploadTypes } from './../models/UploadTypes';
import { AlgoliaService } from './algolia.service';
import { AuthService } from './auth.service';
import { ClientMatterService } from './client-matter.service';
import { DicomUtilitiesService } from './dicom-utilities.service';
import { FirebaseUtilitiesService } from './firebase-utilities.service';
import { GapiOperationsService, HealthCareObject } from './gapi-operations.service';
import { SessionStorageService } from './session-storage.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';

const chunkLength = 10;
const status = ['inprogress', 'completed'];

const dicomdirFileName = 'DICOMDIR';
const uTypes = ['none', UploadTypes.FILE, UploadTypes.VIDEO, UploadTypes.DICOMDisk, UploadTypes.VIDEODisk];
const notPlayableVideoExtensions = ['vob', 'mpeg'];

export interface UploadGroup {
  id?: any;
  createdAt?: number;
  value?: string;
  files?: any;
}

export interface UploadStack {
  id?: any;
  createdAt?: number;
  value?: string;
  files?: any;
}

export interface UploadDaemon {
  status: any;
  currentGroup: string;
  progress: number;
}

export interface FileObject {
  entry: any;
  entryMeta: any;
}

export interface Dicomdir {
  entry: any;
  parentFolder?: any;
  uploadedDate?: any;
  parentFolderId?: any;
  parentFolderName?: any;
  lastModified?: any;
  entryMeta: any;
}

export interface ContextParams {
  creator: any;
  fdate: string;
  ftime?: string;
  fdesc: string;
  clientName: string;
  casename: string;
  currentFolder: Folder;
}

export interface ContextParams2 {
  creator: any;
  fdate: any;
  ftime?: any;
  fdesc: any;
  clientName: any;
  casename: any;
  currentFolder?: any;
  type?: any;
}

export interface EntryMeta {
  entryUID: string;
  progress: number;
  status: string;
  stackID: string;
  target: { folderName: string; folderId: string };
  type?: string;
  desc?: any;
}

export interface FolderTarget {
  folderName: string;
  folderId: string;
}

export interface BlockFile {
  entry: any;
  entryMeta: any;
}

export interface FileRegistrationResult {
  belongsTo: string;
  creator: any;
  fdate: any;
  ftime?: any;
  fileDesc: any;
  fileId: string;
  objectID: string;
  fileName: any;
  filePath: any;
  forDelete: boolean;
  ftype: any;
  type: any;
  lastModified: any;
  notes: any[];
  parentFolderName: any;
  parentFolder: any;
  scanAnalysisId: any;
  storename: string;
  uploadedDate: any;
  viewerurl: string;
}

export interface FileEntry {
  filePath: any;
  file: any;
  name: any;
  lastModified: any;
  size: any;
  type: any;
  webkitRelativePath: any;
}

export interface GenerateDicomDirFileFileParam {
  lastModified: any;
  parentFolderName: any;
  parentFolder: any;
  entryMeta: { desc: any; fdate: any; ftime?: string; targetFolderId: any };
}

export interface UploadBlock2 {
  uploadBlock: any;
  storename: string;
  storeComplementname: any;
}

export interface Params {
  uploadBlock: { casename: any; DICOMMetaData?: DICOMMetaDataUnit[] };
  storename?: string;
  filepath?: string;
  type?: number;
  studyuid?: string;
  caseName?: string;
}

export interface ParamsV1 {
  uploadBlock: any;
  uploadTypes?: string[];
  nameSuffix?: string;
  index?: number;
  targetFolder?: any;
  chunkLength: any;
  storename?: string;
}

export interface TargetFolder {
  folderId: any;
}

export interface MetaData {
  StudyDescription: any;
  StudyInstanceUID: any;
  AccessionNumber: any;
  StudyID: any;
  SeriesNumber: any;
  StudyDate: any;
  StudyTime?: any;
}

export interface DICOMUploadBlock {
  DICOMMetaData: any;
  blockFiles: any[];
  fireStoreFiles: any;
  id: string;
  desc: any;
  type: string;
  fdate: any;
  ftime?: any;
  casename: string;
  clientName: string;
  creator: any;
  createdAt: string;
  value: string;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class UploadHelperService {
  paramsObj: BehaviorSubject<object> = new BehaviorSubject({});
  scannedFilesSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  scannedBlocksSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  uploadBlocksSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  uploadChunkReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  uploadProgressMaximizedO: Subject<boolean> = new Subject();
  fileUploaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _uploadGroups = new BehaviorSubject<UploadGroup[]>([]);
  private _uploadStacks = new BehaviorSubject<UploadStack[]>([]);
  private _uploadDaemon = new BehaviorSubject<UploadDaemon>({ status: 0, currentGroup: '', progress: 0 });
  public action = new BehaviorSubject<string>('');

  readonly uploadGroups = this._uploadGroups.asObservable();
  readonly uploadDaemon = this._uploadDaemon.asObservable();
  readonly uploadStacks = this._uploadStacks.asObservable();

  allowedExts = [[], ['**'], ['mp4', 'mov', 'wmv', 'flv', 'avi'], ['***'], notPlayableVideoExtensions];
  allowFolderUpload: boolean;
  dragAndDropControl: boolean;
  gcloud_projectname: any;
  gcloud_location: any;
  healthcare: any;
  paramsObjO = this.paramsObj.asObservable();
  scannedFiles = this.scannedFilesSource.asObservable();
  storename: string;
  uploadBlocks = this.uploadBlocksSource.asObservable();
  uploadChunkReadyO = this.uploadChunkReady.asObservable();
  up_datasetname: string;
  up_params: any;
  up_storecomplementname: any;
  up_storageRef: any;
  up_db: any;
  uDaemon: any;
  unAvailableStores: { storename: any; casename: any }[];

  public infectedFiles: any[] = [];
  public scanningMonitorInterval: any;

  constructor(
    private gAPI_$: GapiOperationsService,
    private utils_$: UtilsService,
    private auth_$: AuthService,
    private firebase_$: FirebaseUtilitiesService,
    private notifications_$: NotificationsService,
    private uiMessaging_$: UIMessagingService,
    private dicomUtilities_$: DicomUtilitiesService,
    private http: HttpClient,
    private algolia_$: AlgoliaService,
    private sessionStorage_$: SessionStorageService,
    private dialog_$: DialogService,
    private clientMatter_$: ClientMatterService,
  ) {
    this.unAvailableStores = [];
    this.uDaemon = { riseEvent: function () {}, stacks: 0 };

    this.uDaemon.riseEvent = event => {
      switch (event) {
        case 'addgroup':
          this.uDaemon.stacks++;
          break;
        default:
          break;
      }
    };
  }

  arraysSubstraction(source: any[], clearlist: string | any[]) {
    return source.filter(file => this.fileApprove(file, clearlist));
  }

  private createUploadRegistryAuditLog({ entry }: any, params: { uploadBlock: { casename: any } }) {
    this.firebase_$.logService_$.storeLog(
      this.firebase_$.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      {
        filename: entry.name,
        filedate: entry.fdate,
        filecontent: entry.desc,
        filetype: entry.type || '',
      },
      'Upload',
      params.uploadBlock.casename,
    );
  }

  /**
   * Iterates the studies inside the disc and creates a dicomdir instance per study.
   * @returns UploadBlock which consists of one DICOMDIR file instance per study
   */
  createUploadDICOMBlock(
    DICOMDIR: any,
    studiesData: any,
    DICOMFiles: any,
    contextParams: ContextParams,
  ): DICOMUploadBlock {
    const { casename, clientName, creator } = contextParams;
    const newDate = new Date();
    const id = `${casename}_${this.utils_$.getRandomString()}`;

    const DICOMUploadBlock = {
      DICOMMetaData: studiesData,
      blockFiles: this.gettingBlockFiles(studiesData, DICOMDIR, this.getDefaultDate(newDate), id),
      fireStoreFiles: DICOMFiles,
      id: id,
      desc: DICOMDIR.desc,
      type: '3',
      ftime: DICOMDIR.ftime || null,
      fdate: DICOMDIR.fdate,
      casename: casename,
      clientName: clientName,
      creator: creator,
      createdAt: this.utils_$.setDateToString(newDate),
      value: 'Some value',
      status: 'new',
    };

    console.log('DICOMUploadBlock :', DICOMUploadBlock);
    return DICOMUploadBlock;
  }

  currentUploadProgress(progress: number, filename: string, uploadtarget: number, stackID: string) {
    const uploadtargets = [
      { long: 'no target', short: 'no target' },
      { long: 'firebase database', short: 'fbdb' },
      { long: 'firebase storage', short: 'fbst' },
    ];
    let lIndex;
    let lStack;
    const cUS = this._uploadStacks.getValue();

    cUS.map((e, index) => {
      if (e.id === stackID) {
        lIndex = index;
        lStack = e;
        lStack.files.map(d => {
          if (d.name === filename) {
            d.progress = 100;
          }
        });
      } else {
        lIndex = lStack = undefined;
      }
    });

    if (lStack && lIndex) {
      lStack.progress = progress;
      cUS[lIndex] = lStack;
      this._uploadStacks.next(cUS);
    } else {
      console.log('Something failed.');
    }
  }

  //  FIXME: Clear unfilled desc/fdate files (WIP).
  clearUnfilledUploadBlocks() {
    const uploadBlocks = this.uploadBlocksSource.getValue();
    const cleared = uploadBlocks.filter(item => item.desc !== '').filter(item => item.fdate !== '');
    this.uploadBlocksSource.next(cleared);
  }

  //  FIXME: Clear unfilled desc/fdate files (WIP).
  clearScannedBlocksSource() {
    const newScannedBlockSource = [];
    this.scannedBlocksSource.value.forEach(item => {
      const blockFiles = [];
      item.blockFiles.forEach(blockFile => {
        const cond1 = blockFile.entry.fdate !== '';
        const cond2 = blockFile.entry.desc !== '';
        if (cond1 || cond2) blockFiles.push(blockFile);
      });
      if (blockFiles.length) {
        item.blockFiles = blockFiles;
        newScannedBlockSource.push(item);
      }
    });
    this.scannedBlocksSource.next(newScannedBlockSource);
  }

  async dataSetExists(datasetname) {
    const url = `${environment.config.healthcareApi}/projects/${environment.config.gapi.projectname}/locations/${environment.config.gapi.location}/datasets/${datasetname}`;
    return this.http
      .get(url)
      .toPromise()
      .then(
        onfullfilled => {
          console.log('*********************************');
          console.log('dataSetExists onfullfilled :', onfullfilled);
          console.log('*********************************');
          return true;
        },
        onrejected => {
          console.log('*********************************');
          console.log('dataSetExists onrejected :', onrejected);
          console.log('*********************************');
          return false;
        },
      )
      .catch(err => {
        console.error('Error on GET action', err);
        return err;
      });
  }

  /**
   * Clear unAvailableStores var.
   *
   * @memberof UploadHelperService
   */
  enableStores() {
    this.unAvailableStores = [];
  }

  async fbstorage_deleteFolderContents(folderPath) {
    const ref = firebase.storage().ref(folderPath);
    const list = await ref.listAll();

    if (list.items.length === 0) {
      return new Promise(resolve => resolve(true));
    }

    return ref
      .listAll()
      .then(dir => {
        console.log('********************************');
        console.log('List All results', dir);
        console.log('********************************');

        const promisesStack = [];

        dir.items.forEach(fileRef => {
          console.log('____________________________________________');
          console.log('fileRef: ', fileRef);
          console.log('____________________________________________');

          promisesStack.push(this.fbstorage_deleteFile(ref.fullPath, fileRef.name));
        });

        return Promise.all(promisesStack)
          .then(result => {
            console.log('----------------------------------');
            console.log('Delete items result: ', result);
            console.log('----------------------------------');

            const promisesStack2 = [];

            if (dir.items.length) {
              dir.prefixes.forEach(folderRef =>
                promisesStack2.push(this.fbstorage_deleteFolderContents(folderRef.fullPath)),
              );
              return Promise.all(promisesStack2).then(a => a);
            } else {
              return new Promise(resolve => resolve(true));
            }
          })
          .catch(err => {
            console.log('::::::::::::::::::::::::::::::::::::::::::');
            console.log('Promises delete files error', err);
            console.log('::::::::::::::::::::::::::::::::::::::::::');
          });
      })
      .catch(error => {
        console.log('::::::::::::::::::::::::::::::::::::::::::');
        console.log('List All errors', error);
        console.log('::::::::::::::::::::::::::::::::::::::::::');
      });
  }

  fbstorage_deleteFolder(folderPath) {
    console.log('fbstorage_deleteFolder :', folderPath);
    const ref = firebase.storage().ref(folderPath);
    return ref.delete().then(() => {
      console.log('----------------------');
      console.log('The folder has been deleted: ', folderPath);
      console.log('----------------------');
    });
  }

  fbstorage_deleteFile(pathToFile, fileName) {
    return firebase
      .storage()
      .ref(pathToFile)
      .child(fileName)
      .delete()
      .then(() => this.successFileDeletion(fileName))
      .catch(err => this.fileDeletionError(err));
  }

  filterFilesByExt(uType, files) {
    return files.filter(file => this.utils_$.hasValidExt(file.name, this.allowedExts[parseInt(uType, 10)]));
  }

  filterUploadBlockFiles(blocks, uploadblock) {
    const cleanList = [];
    const blockFiles = uploadblock.blockFiles;
    blocks.forEach(block => {
      block.blockFiles.forEach(file => {
        blockFiles.forEach(blockFile => {
          if (file.entry.name === blockFile.entry.name) cleanList.push(blockFile);
        });
      });
    });
    uploadblock.blockFiles = this.arraysSubstraction(blockFiles, cleanList);
    return uploadblock;
  }

  fileApprove(file: { entry: { name: any } }, clearList: string | any[]) {
    for (let index = 0; index < clearList.length; index++) {
      if (clearList[index].entry.name === file.entry.name) return false;
    }
    return true;
  }

  private fileRegistration({ params, file }: { params: any; file: Dicomdir }) {
    return this.firebase_$.fileRegistration({ params, file });
  }

  getVIDEODISKUploadBlock(entries: any[]) {
    return entries
      .filter(videoFile => notPlayableVideoExtensions.includes(this.utils_$.getFileExtension(videoFile.name)))
      .filter(videoFile => videoFile.name.substr(0, 1) !== '.');
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  getScannedFiles(): Observable<any[]> {
    return this.scannedFiles;
  }

  getScannedUploadBlocksList(): Array<UploadBlock> {
    return this.scannedBlocksSource.value;
  }

  async getDICOMDISKUploadBlock(DICOMDIRFile: File, entries: any, contextParams: ContextParams) {
    this.auth_$.showLoader('Getting DICOM files metadata...');

    const { filesData, studiesData } = await this.dicomUtilities_$.getDicomdirMetadata(DICOMDIRFile);
    this.checkingDataErrors(filesData, studiesData);
    this.auth_$.hideLoader();
    const entriesFilteredByExtension = this.utils_$.filterFilesByExt(entries, this.allowedExts[3]);

    const var1 = entriesFilteredByExtension
      .filter(({ name }) => name !== dicomdirFileName)
      .map(file => ({
        lastModified: file.lastModified,
        webkitRelativePath: file.webkitRelativePath,
        name: file.name,
        size: file.size,
        type: file.type,
        filePath: file.webkitRelativePath,
        file: file,
      }));

    // FIXME: One call of filterFilesByExt is enough
    const entriesDataCleaned = var1
      .map((entry: FileEntry) => {
        const find = filesData.find(_file =>
          this.utils_$.isContained(entry.filePath, this.utils_$.cleanFilePath(_file)),
        );

        return find
          ? {
              file: entry.file,
              name: entry.name,
              lastModified: entry.lastModified,
              seriesNumber: find.SeriesNumber,
              size: entry.size,
              studyInstanceUID: find.StudyInstanceUID,
              studyDescription: find.StudyDescription,
              type: entry.type,
              accessionNumber: find.AccessionNumber,
              webkitRelativePath: entry.webkitRelativePath,
            }
          : undefined;
      })
      .filter(item => item !== undefined);

    const cleanedData = entriesDataCleaned;
    const filePaths = filesData.map(item => item.ReferencedFileID.replace(/\.\\/g, '/').replace(/\\/g, '/'));

    // FIXME: One call of filterFilesByExt is enough
    const DICOMFiles = this.utils_$
      .filterFilesByExt(cleanedData, this.allowedExts[3])
      .filter(item => filePaths.filter(filePath => item.webkitRelativePath.indexOf(filePath) > -1).length > 0);
    return this.createUploadDICOMBlock(DICOMDIRFile, studiesData, DICOMFiles, contextParams);
  }

  /**
   * Generate a random name for the dicomstore then assign it to the storename property.
   *
   * @param {string} dataset
   * @param {string} date
   * @return {*}  {string}
   * @memberof UploadHelperService
   */
  generateStoreName(dataset: string, date: string): string {
    const randNum = Math.floor(Math.random() * Math.floor(950));
    this.storename = `${dataset}_${this.utils_$.setDateToString(new Date(date))}_${randNum}`;
    return this.storename;
  }

  /**
   * Return a list of dicomstores of the given client.
   *
   * @param {string} clientname
   * @return {*}
   * @memberof UploadHelperService
   */
  async getDataStoresByClient(clientname: string) {
    // NOTE: Check is dataset with clientname exists.duration
    if (!(await this.dataSetExists(clientname))) {
      await this.handleCreateDataSet(clientname);
      return [];
    }

    return this.http
      .get(this.buildDicomStoresUrl(clientname))
      .toPromise()
      .then(
        data => (!data['dicomStores'] ? [] : data['dicomStores']),
        reason => reason,
      );
  }

  private generateDicomdirFile(name: string, targetFolder: { folderId: any }, file: GenerateDicomDirFileFileParam) {
    const { lastModified, parentFolderName, parentFolder } = file;
    const { desc, fdate, targetFolderId, ftime } = file.entryMeta;
    const uploadedDate = new Date().toString();
    const entry = { name, desc: desc || '', fdate: fdate || '', ftime: ftime || null };
    const entryMeta = {
      ftime: ftime || null,
      fdate: fdate || '',
      targetFolderId: targetFolderId || targetFolder.folderId || '',
    };

    return <Dicomdir>{
      entry,
      entryMeta,
      uploadedDate,
      lastModified,
      parentFolderName,
      parentFolder,
    };
  }

  getRandomStrings(length) {
    const value = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randoms = [];
    for (let i = 0; i < length; i++) randoms.push(value[Math.floor(Math.random() * value.length)]);
    return randoms.join('');
  }

  /**
   * Get the total size of the upload block for DICOM disk files.
   */
  getDICOMStackSize(ustack: any): number {
    let blockTotalSize = 0;
    ustack.fireStoreFiles.forEach(file => (blockTotalSize += file.size));
    return blockTotalSize;
  }

  /**
   * Get the total size of the upload block for files.
   */
  getFilesStackSize(ustack: any): number {
    let blockTotalSize = 0;
    ustack.blockFiles.forEach(file => (blockTotalSize += file.entry.size));
    return blockTotalSize;
  }

  async handleScan(scanId) {
    if (!scanId || scanId === '') {
      console.log('No scan id provided');
      return;
    }
    if (scanId.indexOf('*') >= 0) {
      console.log('Scan id contains *');
    }
    const scanParts = scanId.split('*');
    const url = `${environment.constants.cloudfunctionsURL}${environment.config.malwarescanner.endpoint}-getScanResult?id=${scanParts[0]}`;

    const scanResults = await this.http.get(url).toPromise();

    if (scanResults['data']['attributes']['status'] === status[1]) {
      const analysisId = scanResults['data']['id'];
      this.sessionStorage_$.removePendingScan(analysisId, '*');
      const results = scanResults['data']['attributes']['results'];
      const detected = [];

      Object.keys(results).forEach(key => {
        if (results[key]['result'] !== null) detected.push(results[key]['result']);
      });

      if (detected.length > 0) {
        this.uiMessaging_$.toastMessage('Scan completed, malicious file detected, removing the file is on going.', '');
        this.dialog_$.open(SimpleMessageWindowComponent, { data: this.getDetectedFileMessageData() });
        this.removeMaliciousFile(analysisId, scanParts[1]);
      } else console.log('No malicious file detected');
    }
  }

  /**
   * Handle an upload stack when no DICOM disk has been selected.
   *
   * @param {ParamsV1} params
   * @memberof UploadHelperService
   */
  async handleSimpleFiles(params: ParamsV1) {
    const { uploadBlock, chunkLength } = params;

    for (let i = 0; i < uploadBlock.blockFiles.length; i += chunkLength) {
      let counter = 0;
      for (let fileObject of uploadBlock.blockFiles.slice(i, i + chunkLength)) {
        if (fileObject.entryMeta.type === 'dicom') {
          await this.handleDicomFile(params, counter, uploadBlock.casename, uploadBlock.fdate, fileObject, {
            ftime: uploadBlock.ftime,
          });
        } else {
          await this.handleNoDicomFile(fileObject, `t_${this.utils_$.getRandomString_(15)}`, params);
        }

        this.fileUploaded.next(true);
        uploadBlock.setStatus(status[1]);
        counter++;
      }
    }

    this.algolia_$.storeToAlgolia();
    this.allowFolderUploadAction(params);
    this.uiMessaging_$.toastMessage('Files Upload completed', 'STATUS');
  }

  private getStudyUID(studyInstanceUID) {
    return studyInstanceUID ? `/study/${studyInstanceUID}` : null;
  }

  /**
   * Add studyuid, filepath and caseName to params.
   *
   * @private
   * @param {*} params
   * @param {*} index
   * @return {*}
   * @memberof UploadHelperService
   */
  private updateParams(params: Params, index: number): any {
    params.studyuid = this.getStudyUID(params.uploadBlock.DICOMMetaData[index].StudyInstanceUID);
    params.filepath = `patient/${params.uploadBlock.casename}/${dicomdirFileName}`;
    params.caseName = params.uploadBlock.casename;
    return params;
  }

  async handleDICOMDisk(params: any) {
    const { uploadBlock, targetFolder } = params;
    const { blockFiles, DICOMMetaData, id } = uploadBlock;

    if (DICOMMetaData.length > 0) {
      // There is at least one StudyUID on DICOMDIR file.
      for (let index = 0; index < DICOMMetaData.length; index++) {
        const _file = blockFiles[index];
        if (_file.toUpload === false) {
          console.log("This file won't be uploaded.", _file);
        } else {
          await this.handleUpload(params, _file, index, targetFolder);
        }
      }
    }

    const global = this.getGlobal(params);

    this.currentUploadProgress(global, dicomdirFileName, 1, id);
    // document.getElementById('progbar').style.width = `${global}%`;

    // NOTE: DICOMDIR file included on firestore could cause failure during the import process.
    this.dragAndDropControl = true;
    this.allowFolderUpload = true;

    params.uploadBlock.setStatus('inprogress');

    // HANDLE DATASTORE CREATION
    await this.handleDataStoreCreation(params);
    const importSuccess = await this.handleDICOMImport(params);

    if (!importSuccess) {
      this.uiMessaging_$.toastMessage('DICOM Import failed', 'ERROR');
      return;
    }

    this.DICOMImportFinished(params.uploadBlock);
  }

  /**
   * Generates the HealthCare Object to be uploaded. Then upload it.
   *
   * @param {*} params
   * @return {*}
   * @memberof UploadHelperService
   */
  async handleDICOMImport(params: any) {
    const { uploadBlock } = params;
    params.uploadBlock.blockFiles = this.getUploadBlockFiles(uploadBlock);
    const authorizedNumbers = this.getAuthorizedAccessionNumbers(uploadBlock.blockFiles);

    // Create HealthCare Object.
    const filteredFiles = this.getFilteredFiles(authorizedNumbers, this.getUploadBlockFireStoreFiles(uploadBlock));
    const healthCareObject = await this.generateHealthCareObject(params, filteredFiles);

    // const totalSizeOfUpload = this.calculateTotalSizeOfTheUpload(healthCareObject.files);

    if (!healthCareObject.files.length) {
      console.log('No files to upload');
      return false;
    }

    return this.uploadHealthCareObject(healthCareObject);
  }

  reproduceDicomDirFileInClio(params) {
    this.clientMatter_$.generateDicomDirFile(params);
  }

  checkIfParentFolderIsClio(parentFolder) {
    return parentFolder.folderName === 'Clio';
  }

  /**
   * Handle the Malware Scan for a file.
   *
   * @param {*} file
   * @param {*} temporalScanCode
   * @memberof UploadHelperService
   */
  async malwareScan(file: any, temporalScanCode: any) {
    const malwareScanResults = await this.sendFileToMalwareScan(<File>file);
    const { id, attributes } = malwareScanResults['result'].data;
    const ananlysisId = id;
    const ananlysisStatus = attributes.status;
    const ananlysisResults = attributes.results;

    if (ananlysisStatus === status[1] && this.validateScanResult(ananlysisResults)) {
      const msg = 'Malicious file detected. Removal in progress.';
      console.log('msg :', msg);
      this.uiMessaging_$.toastMessage('Scan completed, malicious file detected, removing the file is on going.', '');
      this.dialog_$.open(SimpleMessageWindowComponent, { data: this.getDetectedFileMessageData() });
      this.removeMaliciousFile(ananlysisId, temporalScanCode);
    } else {
      this.sessionStorage_$.setPendingScan(ananlysisId, temporalScanCode);
      this.setScanningMonitoring(10000);
    }
  }

  matchDataStoresByClient(storename: string, dataStoresByClient: any) {
    const dStores = dataStoresByClient.map(item =>
      item.name.substr(item.name.lastIndexOf('/') + 1, item.name.length - 1),
    );
    return dStores.includes(storename);
  }

  /**
   * Store selected files or folders into blocks of files in scannedBlockSource,
   * this is a temporal store before click the UPLOAD button and send them to cloud
   * store.
   *
   * @param {UploadBlock} uploadBlock
   * @return {*}  {boolean}
   * @memberof UploadHelperService
   */
  pushUploadBlock(uploadBlock: UploadBlock): boolean {
    if (!uploadBlock.blockFiles) {
      this.uiMessaging_$.toastMessage('This upload block has no files inside it, please retry.', 'ERROR');
      return;
    }

    uploadBlock.ugid = this.getRandomString();
    uploadBlock.fdate = new Date().toString();
    uploadBlock.blockFiles.forEach((file, index) => {
      file.ugid = uploadBlock.ugid;
      file.fileUploadCode = this.getRandomString(8);
      if (file.entryMeta.type === 'dicom') file.DICOMMetaData = uploadBlock.DICOMMetaData[index];
    });

    const blocks = this.scannedBlocksSource.value;
    const uploadBlockCleaned = this.filterUploadBlockFiles(blocks, uploadBlock);

    if (uploadBlockCleaned.blockFiles.length > 0) {
      this.scannedBlocksSource.next([...this.scannedBlocksSource.value, uploadBlockCleaned]);
      return true;
    } else return false;
  }

  /**
   *
   *
   * @param {*} analysisId
   * @param {*} tempScanCode
   * @return {*}
   * @memberof UploadHelperService
   */
  async removeMaliciousFile(analysisId: any, tempScanCode: any) {
    if (!analysisId) {
      console.log('No analysis id provided');
      return;
    }

    const doc = (
      await firebase.firestore().collection('files').where('scanAnalysisId', '==', tempScanCode).limit(1).get()
    ).docs[0];

    const fileInfo = doc.data();
    const storageLocation = fileInfo.filePath;
    const storageRef = firebase.storage().ref().child(storageLocation);

    // Delete the file
    storageRef
      .delete()
      .then(() => {
        this.uiMessaging_$.toastMessage('Malicious file removed.', '');
        this.action.next('update');
      })
      .catch(error => this.uiMessaging_$.toastMessage('There was an error on file removal', ''));
    doc.ref
      .delete()
      .then(() => {
        this.uiMessaging_$.toastMessage('Malicious file reference removed.', '');
        this.action.next('update');
      })
      .catch(error => {});
  }

  /**
   * Remove the completed upload blocks from the scannedBlocksSource.
   *
   * @memberof UploadHelperService
   */
  removeCompleted() {
    const nextScannedBlockSource = this.scannedBlocksSource.value.filter(item => item.status !== status[1]);
    this.scannedBlocksSource.next(nextScannedBlockSource);
  }

  /**
   * Remove a specific upload block from the scannedBlocksSource.
   *
   * @param {string} ugid Upload Block ID
   * @memberof UploadHelperService
   */
  removeUploadBlock(ugid: string) {
    const clonedSBS = this.scannedBlocksSource.value.filter((item, index) => item.ugid !== ugid);
    this.scannedBlocksSource.next(clonedSBS);
  }

  /**
   * Remove a specific file from a specific upload block.
   *
   * @param {string} name
   * @param {string} ugid
   * @memberof UploadHelperService
   */
  removeNode(name: string, ugid: string) {
    const blocksToRemove = [];
    this.scannedBlocksSource.value.forEach((uploadBlock: UploadBlock) => {
      if (uploadBlock.ugid === ugid) {
        const remaining = uploadBlock.deleteBlockFile(name);
        if (remaining.length === 0) blocksToRemove.push(ugid);
      }
    });

    // Remove BLOCKS.
    const remainig = this.scannedBlocksSource.value.filter(ub => !blocksToRemove.includes(ub.ugid));
    this.scannedBlocksSource.next(remainig);
  }

  /**
   * Set a specific uploadStack with status in-progress: true.
   *
   * @param {string} uploadStackID
   * @memberof UploadHelperService
   */
  setInProgress(uploadStackID: string) {
    const newScannedBlocksSource = [];
    this.scannedBlocksSource.value.forEach(item => {
      if (item.id === uploadStackID) item.inprogress = true;
      newScannedBlocksSource.push(item);
    });
    this.scannedBlocksSource.next(newScannedBlocksSource);
  }

  setScanningMonitoring(time) {
    if (!this.scanningMonitorInterval && this.sessionStorage_$.getScanIds() !== '') {
      this.scanningMonitorInterval = setInterval(() => {
        const scanIds = this.sessionStorage_$.getScanIds().split(',');
        console.log('scanIds :', scanIds);
        scanIds.forEach(scanId => this.handleScan(scanId));
      }, time);
    } else console.log('Scanning monitoring already set');
  }

  /**
   * Sort upload stack by smallest first.
   *
   * @param {any[]} ustacks
   * @return {*}  {*}
   * @memberof UploadHelperService
   */
  sortUStacksBySize(ustacks: any[]): any {
    ustacks.forEach(
      ustack =>
        (ustack.totalsize = ustack.type === '3' ? this.getDICOMStackSize(ustack) : this.getFilesStackSize(ustack)),
    );
    return ustacks.sort((a, b) => (a.totalsize > b.totalSize ? 1 : -1));
  }

  /**
   * Get the upload promise.
   *
   * @private
   * @param {*} uploadBlock
   * @param {*} targetFolder
   * @param {*} options
   * @param {*} chunkLength
   * @return {*}
   * @memberof UploadHelperService
   */
  private getUploadPromise(uploadBlock: any, targetFolder: any, options: any, chunkLength: any): any {
    const nameSuffix = this.getNameSuffix(uploadBlock);
    const index = 0;
    let promise;
    if (uploadBlock.type === '3')
      promise = this.handleDICOMDisk({
        uploadBlock: uploadBlock,
        uploadTypes: uTypes,
        nameSuffix: nameSuffix,
        index: index,
        targetFolder: targetFolder,
        storename: this.generateStoreName(uploadBlock.casename, uploadBlock.fdate),
        options: options,
      });
    else
      promise = this.handleSimpleFiles({
        uploadBlock,
        uploadTypes: uTypes,
        nameSuffix,
        index,
        targetFolder,
        chunkLength,
      });
    return promise;
  }

  /**
   * Takes upload stacks, put them inprogress and
   * send them to the server.
   *
   * @param {*} [targetFolder]
   * @param {*} [options]
   * @return {*}  {Promise<any>}
   * @memberof UploadHelperService
   */
  async startUpload(targetFolder?: any, options?: any): Promise<any> {
    // FIXME: Is this working?
    /*
    this.setDaemonStatus('starting');
    this.setDaemonProgress(0);
    */

    this.sortScannedBlockSource();
    const totalDicoms = this.scannedBlocksSource.value.filter(item => item.type === '3').length;
    this.increaseTotalStudies(totalDicoms, options.owner);
    return this.runUpload(this.scannedBlocksSource.value, targetFolder, options, chunkLength);
  }

  increaseTotalStudies(totalDicoms: number, ownerID) {
    this.firebase_$.increaseOwnerTotalStudies(ownerID, totalDicoms);
  }

  /**
   * Store DCMs in Firebase Storage.
   *
   * @param filteredFiles - data
   * @param dataStorePath
   * @param chunksize
   * @param counter
   */
  async storeDCMFiles(filteredFiles: string | any[], dataStorePath: string, chunksize: number, datastore: any) {
    const resultsStack = [];
    let counter = 0;

    for (let v = 0; v < filteredFiles.length; v++) {
      const promisesStack = [];
      let chunkCounter = 0;

      for (let h = 0; h < chunksize; h++) {
        const fileEntry = filteredFiles[counter];
        if (!fileEntry) continue;
        const studyDescription = this.getStudyDescription(fileEntry);
        const file = fileEntry.file || fileEntry.entry;
        if (!file) continue;
        if (!studyDescription) console.log('No studyDescription for file :', file);
        const promise = this.getStorageDicomFilePromise(
          file,
          dataStorePath,
          datastore,
          studyDescription,
          counter,
          filteredFiles.length,
        );
        promisesStack.push(promise);
        // Store the reference in Algolia.
        this.firebase_$.algoliaObjects.push(file);
        counter++;
        chunkCounter++;
      }

      this.uiMessaging_$.toastMessage('Placing DICOM files...', 'INFORMATION', { duration: 10000 });

      // When all Store Actions are done.
      await Promise.all(promisesStack)
        .then(r => {
          resultsStack.push(r);
          console.log('storeDICOMFileInFirebaseStorage storing files result:', r);
          this.uiMessaging_$.closeToastMessage();
        })
        .catch(e => console.error('storeDICOMFileInFirebaseStorage storing files error:', e));

      v += chunksize - 1;
    }

    return resultsStack;
  }

  /**
   * What to do after the DICOM Files import to Google HealthCare process.
   *
   * @param {number} importResult
   * @param {string} [dataStorePath]
   * @memberof UploadHelperService
   */
  handleImportResults(importResult: number, dataStorePath?: string) {
    console.log('importResult: ', importResult);
    if (importResult === 0 || importResult['ok'] === false) {
      this.uiMessaging_$.toastMessage(`There was an error during the importing process.`, null);
    } else {
      // Enable Stores.
      this.unAvailableStores = [];
      this.uiMessaging_$.toastMessage(`DICOM disc (medical images) imported successfully.`, null, { duration: 0 });

      // NOTE: Has to send an email...

      // NOTE: Delete the folder after 10 seconds.
      // this.utils_$.wait(15000).then(() => this.runDicomStorageFolderDeletion(dataStorePath));
    }
  }

  /**
   * Upload DICOM Disk to Google HealthCare.
   *
   * @param {*} healthcareCreatedObj: HealthCareObject
   * @return {*}
   * @memberof UploadHelperService
   */
  async uploadHealthCareObject(healthcareCreatedObj: HealthCareObject) {
    const { files, dataset, datastore } = healthcareCreatedObj;
    const dataStorePath = `dcmstore/${dataset}/${datastore}/`;

    // Avoid DICOMDisk and DICOMDIR
    const filteredFiles = files.filter(file => ![dicomdirFileName, 'DICOMDisk'].includes(file.name));
    const chunksize = 100;
    const result = await this.storeDCMFiles(filteredFiles, dataStorePath, chunksize, datastore);
    console.log('result storeDCMFiles: ', result);

    // UI Information.
    this.uiMessaging_$.toastMessage('DICOM disc (medical images) uploaded successfully.', null);

    const userEmail = this.auth_$.userData.getValue()['email'];

    // Import DICOM Files to Google HealthCare.
    return this.gAPI_$
      .importDICOMFiles(dataset, datastore, userEmail)
      .then(importResult => {
        this.handleImportResults(importResult, dataStorePath);
        return importResult;
      })
      .catch(err => {
        console.log('++++++++++++++++++++++++++++++++');
        console.log('Error in IMPORT DICOM FILES', err);
        console.log('++++++++++++++++++++++++++++++++');
        err;
      });
  }

  sendFileToMalwareScan(entry: any) {
    const malwareScannerEndpoint = `${environment.constants.cloudfunctionsURL}${environment.config.malwarescanner.endpoint}-analyzeFile`;
    const form = document.createElement('form');
    const formData = new FormData(form);
    formData.append('myupload', entry, entry.name);
    return this.http.post(malwareScannerEndpoint, formData).toPromise();
  }

  /**
   * Update Scanned Block Source with the fdate and desc values.
   * @param ugid is the indentifier of the block to be modified.
   * @param node has the new values.
   */
  updateScannedBlockSource(
    ugid: string,
    node: { entryUID: any; fdate: any; desc: any; target: any; fileUploadCode: any },
  ) {
    const { fdate, desc, target, fileUploadCode } = node;
    const { folderId, folderName } = target;
    if (!ugid) {
      console.error('The ugid is undefined.');
      return;
    }

    const newScannedBlockSource = [];
    let updateFlag = false;

    this.scannedBlocksSource.value.forEach(item => {
      if (item.ugid === ugid) {
        updateFlag = true;
        const blockFiles = [];
        item.blockFiles.forEach(blockFile => {
          if (blockFile.fileUploadCode === fileUploadCode) {
            blockFile.entry.fdate = fdate;
            blockFile.entry.desc = desc;
            blockFile.entryMeta.desc = desc;
            blockFile.entryMeta.targetFolderId = folderId;
            blockFile.entryMeta.targetFolderName = folderName;
          }
          blockFiles.push(blockFile);
        });
        item.blockFiles = blockFiles;
        item.fdate = fdate;
      }
      newScannedBlockSource.push(item);
    });

    if (updateFlag) this.scannedBlocksSource.next(newScannedBlockSource);
    else console.log(`There is no ugid that match with ${ugid} inside scannedBlocksSource`);
  }

  validateScanResult(scanResult: any) {
    const results = scanResult;
    const detected = [];
    Object.keys(results).forEach(key => {
      if (results[key]['result'] !== null) {
        detected.push(results[key]['result']);
      }
    });
    if (detected.length > 0) {
      console.log('Detected');
      return true;
    } else {
      console.log('Not Detected');
      return false;
    }
  }

  getAuthorizedAccessionNumbers(blockFiles: any[]) {
    return blockFiles
      .filter(({ toUpload }) => toUpload !== false)
      .map(({ entry }) => entry.accessionNumber)
      .filter(accessionNumber => accessionNumber !== undefined);
  }

  getFilteredFiles(authorizedAccessionNumbers: string | any[], files: any[]) {
    return authorizedAccessionNumbers.length
      ? files
          .filter(({ name }) => name !== dicomdirFileName)
          .filter(({ accessionNumber }) => authorizedAccessionNumbers.includes(accessionNumber))
      : files.filter(({ name }) => name !== dicomdirFileName);
  }

  /**
   * Generates the HealthCare object, previous to import it into Google HealthCare Storage.
   *
   * @param {UploadBlock2} { uploadBlock, storename, storeComplementname }
   * @param {*} dicomFiles
   * @return {*}
   * @memberof UploadHelperService
   */
  generateHealthCareObject({ uploadBlock, storename, storeComplementname }: UploadBlock2, dicomFiles: any): any {
    return this.gAPI_$.healthcareCreate({
      datasetname: uploadBlock.casename.replace(/\s/g, ''),
      datastorename: storename.replace(/\s/g, ''),
      files: dicomFiles,
      date: uploadBlock.fdate,
      storecomplementname: storeComplementname ? storeComplementname.replace(/\s/g, '') : '',
      stackID: uploadBlock.id,
    });
  }

  formatYearMonthDate(datestring: string) {
    const year = datestring.substring(0, 4);
    const month = datestring.substring(4, 6);
    const day = datestring.substring(6, 8);
    // return `${year}/${month}/${day}`;
    return `${month}/${day}/${year}`;
  }

  checkIfDicomFile(file: { name: string }) {
    const extension = this.utils_$.getFileExtension(file.name);
    return extension === '' || extension === 'dcm' ? true : false;
  }

  private generateFakeDicomdirFile(
    name: string,
    file: { fdate?: any; lastModified?: any; parentFolderName?: any; parentFolder?: any },
    entryMeta: { targetFolderName: string; desc: any; fdate: any; targetFolderId: any },
  ) {
    entryMeta = this.getEntryMeta(entryMeta, file);
    return this.getDicomDir(this.getEntry(name, entryMeta, file), entryMeta, file);
  }

  /**
   * Creates the Healthcare Object and then import it into Google Healthcare API.
   *
   * @private
   * @param {*} uploadBlock
   * @param {*} dicomFiles
   * @return {*}
   * @memberof UploadHelperService
   */
  private async handlingHealthCareJob(uploadBlock: any, dicomFiles: any) {
    // Generate HealthcareObject.
    const uploadBlock2 = this.generateUploadBlock2(uploadBlock);
    const healthCareObject = await this.generateHealthCareObject(uploadBlock2, dicomFiles);

    // Test HealthcareObject.
    if (!this.handleCheckResult(healthCareObject)) return;

    //NOTE - Run the import process.
    const dicomImportResult = await this.uploadHealthCareObject(healthCareObject);

    if (!dicomImportResult) {
      console.log('DICOM Import failed');
      this.uiMessaging_$.toastMessage('DICOM Import failed', 'ERROR');
      return;
    } else {
      console.log('DICOM Import success');
      this.uiMessaging_$.toastMessage('DICOM Import success', 'STATUS');
    }

    this.singleDICOMImportFinished(uploadBlock);
  }

  private getNameSuffix(uploadBlock: { fdate: string | number | Date }) {
    return this.utils_$.getNewStoreName_complement(new Date(uploadBlock.fdate).valueOf().toString());
  }
  async createUploadBlock(filteredEntries: any[], contextParams: ContextParams2) {
    const cleanCaseName = this.utils_$.cleanString(contextParams.casename);
    const DICOMMetadata = [];
    const stackID = `${cleanCaseName}_${this.utils_$.getRandomString()}`;

    const params = {
      filteredEntries,
      fdate: contextParams.fdate,
      fdesc: contextParams.fdesc,
      entryUID: this.utils_$.generateUniqueId(10, 'entry'),
      progress: 0,
      stackID: stackID,
      status: 'new',
      target: { folderName: '', folderId: '' },
      DICOMMetadata,
    };

    return {
      blockFiles: await this.populateBlockFiles(params),
      fireStoreFiles: [],
      casename: contextParams.casename,
      clientName: contextParams.clientName,
      creator: contextParams.creator,
      desc: contextParams.fdesc,
      DICOMMetaData: DICOMMetadata,
      fdate: contextParams.fdate,
      id: stackID,
      status: 'new',
      type: contextParams.type,
      value: 'Some value',
      currentFolder: contextParams.currentFolder,
    };
  }

  private getStudyDescription(fileEntry) {
    return fileEntry.DICOMMetaData ? fileEntry.DICOMMetaData.StudyDescription : fileEntry.studyDescription;
  }

  private getGlobal(params) {
    return (
      (params.index * 100) /
      params.uploadBlock.blockFiles.filter(file => typeof (file.toUpload === 'boolean') && file.toUpload === false)
        .length
    );
  }

  private gettingBlockFiles(DICOMMetaData: string | any[], entry: any, defaultDate: string, stackID: string) {
    const blockFiles = [];
    for (let index = 0; index < DICOMMetaData.length; index++) {
      const metadata = DICOMMetaData[index];
      const entryUID = this.utils_$.generateUniqueId(10, 'entry');
      const entryMeta = this.buildEntryMetaV2(entryUID, stackID, metadata);
      const singleEntry = this.buildSingleEntryV2(entry, entryUID, metadata, defaultDate);

      blockFiles.push({ entry: singleEntry, entryMeta, lastModified: entry.lastModified });
    }
    return blockFiles;
  }

  private async handleNoDicomFile(
    fileObject: { entry: File; entryMeta: { targetFolderName: any } },
    scanCode: string,
    params: Params,
  ) {
    const file = this.updateFileObject(fileObject, fileObject.entry, fileObject.entryMeta);
    this.malwareScan(<File>fileObject.entry, scanCode);
    params = this.updateParamsV2(params, scanCode, params.uploadBlock, fileObject.entry);

    // Add the file to storage.
    await this.firebase_$
      .fileStorageAdd(file, params.filepath)
      .catch(error => console.log('fileStorageAdd error:', error));

    await this.afterFileStorageAddition(params, file);
  }

  private async handleDicomFile(params, counter, casename, fdate, fileObject, options) {
    // FIXME: Review this
    // this.malwareScan(<File>entry, scanCode);

    params = this.updateParams(params, counter);
    const storename = this.generateStoreName(casename, fileObject.entry.fdate || fdate || new Date().toString());
    const uploadBlock = { ...params.uploadBlock, blockFiles: [fileObject] };

    params = { ...params, storename, up_datastorename: storename, type: 3, uploadBlock };

    //NOTE - Create the DataStore.
    const createDataStoreResult = await this.gAPI_$.createDataStore(
      storename,
      casename,
      this.gAPI_$.gcloud_projectname,
      this.gAPI_$.gcloud_projectlocation,
    );

    console.log('createDataStoreResult: ', createDataStoreResult);

    ////////////////////// HANDLING DICOMDIR FILE ///////////////////////
    this.unAvailableStores.push({ storename, casename });

    //NOTE - File storing. (Storage)
    const file = this.generateFakeDicomdirFile(dicomdirFileName, fileObject.entry, fileObject.entryMeta);

    await this.storeTheFile(file, params);
    await this.afterFileStorageAddition(params, file);
    ////////////////////// END DICOMDIR FILE ///////////////////////

    await this.handlingHealthCareJob(uploadBlock, params.uploadBlock.blockFiles);
  }

  private getDefaultDate(newDate) {
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
  }

  private getFilePathV1(dataStorePath, datastore, file) {
    return `${dataStorePath}${datastore}_${file.name}_${Date.now()}_${this.getRandomStrings(4)}`;
  }

  private getStorageDicomFilePromise(file, dataStorePath, datastore, StudyDescription, counter, total) {
    return this.firebase_$.storeDICOMFileInFirebaseStorage(file, this.getFilePathV1(dataStorePath, datastore, file), {
      studyDescription: StudyDescription,
      counter,
      total,
    });
  }

  /**
   * Adding lastModified, parentFolderName and uploadedDate to the fileObject.
   *
   * @private
   * @param {*} fileObject
   * @param {*} entry
   * @param {*} entryMeta
   * @return {*}
   * @memberof UploadHelperService
   */
  private updateFileObject(fileObject: any, entry: { lastModified: any }, entryMeta: { targetFolderName: any }): any {
    return {
      ...fileObject,
      lastModified: entry.lastModified,
      parentFolderName: entryMeta.targetFolderName,
      uploadedDate: new Date().toString(),
    };
  }

  private async afterFileStorageAddition(params: Params, file: any): Promise<void> {
    // File registering. (Firestore)
    const fileData = await this.fileRegistration({ params, file: file });

    // Create notification.
    // NOTE: Compare with PROD-download-disc branch.
    this.createNotifications(fileData, params, file, NotificationTypes.newfile);

    // File indexing. (Algolia)
    this.firebase_$.algoliaObjects.push(fileData);

    // Add upload registry to Audit Log.
    this.createUploadRegistryAuditLog(file, params);
  }

  private allowFolderUploadAction(params) {
    switch (params.uploadBlock.type) {
      case '1':
      case '2':
        this.allowFolderUpload = false;
        break;
      case '4':
        this.dragAndDropControl = true;
        this.allowFolderUpload = true;
        break;
      default:
        break;
    }
  }

  /**
   * Add scanAnalysisId, filepath, studyuid, lastModified and type to the params.
   *
   * @private
   * @param {*} params
   * @param {*} scanCode
   * @param {*} uploadBlock
   * @param {*} entry
   * @return {*}
   * @memberof UploadHelperService
   */
  private updateParamsV2(params: any, scanCode: any, uploadBlock: any, entry: any): any {
    return {
      ...params,
      scanAnalysisId: scanCode,
      filepath: `patient/${uploadBlock.casename}/${entry.name}`,
      studyuid: null,
      lastModified: new Date(entry.lastModified).toString(),
      type: 1,
    };
  }

  private runUpload(scannedBlocksSource, targetFolder, options, chunkLength) {
    const promisesStack = [];

    for (let index = 0; index < scannedBlocksSource.length; index++) {
      if (!status.includes(scannedBlocksSource[index].getStatus())) {
        scannedBlocksSource[index].setStatus(status[0]);
        this.setInProgress(scannedBlocksSource[index].id);
        promisesStack.push(this.getUploadPromise(scannedBlocksSource[index], targetFolder, options, chunkLength));
      }
    }
    return Promise.all(promisesStack);
  }

  private sortScannedBlockSource() {
    this.scannedBlocksSource.next(this.sortUStacksBySize(this.scannedBlocksSource.value));
  }

  private buildEntryMetaV2(
    entryUID: string,
    stackID: string,
    metadata: { StudyDate: any; StudyTime?: string; StudyDescription: any },
  ) {
    const { StudyDate, StudyTime, StudyDescription } = metadata;

    const status = 'new';
    const progress = 0;

    const target = { folderName: '', folderId: '' };

    const fdate = this.utils_$.dateToString(StudyDate);
    const ftime = this.utils_$.timeToString(StudyTime) || null;
    const studyDescription = StudyDescription ? StudyDescription.trim() : '-';

    return {
      fdate,
      ftime,
      desc: studyDescription,
      entryUID,
      progress,
      status,
      stackID,
      target,
    };
  }

  private async populateBlockFiles(params) {
    const { filteredEntries } = params;
    const result = [];

    for (let index = 0; index < filteredEntries.length; index++) {
      const entryData = await this.getEntryData({
        status: params.status,
        entry: filteredEntries[index],
        fdesc: params.fdesc,
        DICOMMetadata: params.DICOMMetadata,
        entryUID: params.entryUID,
        progress: params.progress,
        stackID: params.stackID,
        target: params.target,
        fdate: params.fdate,
      });
      result.push({ entry: entryData.entry, entryMeta: entryData.entryMeta });
    }
    return result;
  }

  private buildSingleEntryV2(entry: any, entryUID: string, metadata: MetaData, defaultDate: string) {
    const { StudyDescription, StudyInstanceUID, AccessionNumber, StudyID, SeriesNumber, StudyDate, StudyTime } =
      metadata;
    const studyDescription = StudyDescription ? StudyDescription.trim() : '-';
    const studyDate = StudyDate ? this.utils_$.dateStringToFormattedString(StudyDate) : defaultDate;
    const studyTime = StudyTime || null;

    return {
      ...entry,
      desc: studyDescription,
      studyID: StudyID,
      studyInstanceUID: StudyInstanceUID,
      seriesNumber: SeriesNumber,
      accessionNumber: AccessionNumber,
      entryUID: entryUID,
      studyDate: studyDate,
      studyTime: studyTime,
      fdate: studyDate,
    };
  }

  private async handleUpload(
    params: Params,
    _file: GenerateDicomDirFileFileParam,
    index: number,
    targetFolder: TargetFolder,
  ) {
    console.log('This file will be uploaded.', _file);
    params = this.updateParams(params, index);

    const dicomdirFile = this.generateDicomdirFile(dicomdirFileName, targetFolder, _file);

    console.log('-- dicomdirFile: ', dicomdirFile);
    this.unAvailableStores.push({ storename: params.storename, casename: params.uploadBlock.casename });

    // File storing. (Storage)
    await this.firebase_$.fileStorageAdd(dicomdirFile, params.filepath);
    params = { ...params, type: 3 };
    await this.afterFileStorageAddition(params, dicomdirFile);
  }

  private async getEntryData(params) {
    let { status, entry, fdesc, DICOMMetadata, entryUID, progress, stackID, target, fdate } = params;
    let entryMeta;

    if (this.checkIfDicomFile(entry)) {
      const dicomFileData = await this.dicomUtilities_$.getDICOMFileData(entry);
      const studyData = JSON.parse(dicomFileData.data)[0];

      this.modifyEntry(entry, [
        { key: 'fdate', value: this.formatYearMonthDate(studyData.StudyDate) || '' },
        { key: 'fdesc', value: studyData.StudyDescription || fdesc || '' },
        { key: 'ftime', value: studyData.StudyTime || null },
      ]);

      this.feedDICOMMetadata(studyData, DICOMMetadata);
      entryMeta = { entryUID, progress, status, stackID, target, type: 'dicom', desc: entry.desc };
    } else {
      this.modifyEntry(entry, [
        { key: 'fdate', value: fdate },
        { key: 'fdesc', value: fdesc || '' },
      ]);

      this.feedDICOMMetadata({}, DICOMMetadata);
      entryMeta = { entryUID, progress, status, stackID, target };
    }

    return { entry, entryMeta };
  }

  private feedDICOMMetadata(metadataUnit, DICOMMetadata) {
    DICOMMetadata.push(metadataUnit);
  }

  private modifyEntry(entry, params: any[]) {
    params.forEach(p => (entry[p.key] = p.value));
  }

  /**
   *
   *
   * @param {*} filesData
   * @param {*} studiesData
   * @return {*}
   * @memberof UploadHelperService
   */
  checkingDataErrors(filesData: any, studiesData: any): any {
    if (!filesData || filesData.length === 0) {
      console.error('Something went wrong with the DICOMDIR file, filesData is empty');
      return;
    }

    if (!studiesData || studiesData.length === 0) {
      console.error('Something went wrong with the DICOMDIR file, filesData is empty');
      return;
    }
  }

  /**
   *
   *
   * @param {File[]} files
   * @return {*}
   * @memberof UploadHelperService
   */
  calculateTotalSizeOfTheUpload(files: File[]): any {
    let sum = 0;
    files.reduce((acc, file) => {
      sum += file.size;
      return acc;
    }, 0);
    return sum / 1024 / 1024;
  }

  /**
   *
   *
   * @private
   * @param {*} fileName
   * @memberof UploadHelperService
   */
  private successFileDeletion(fileName: any) {
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('The file has been deleted: ', fileName);
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  }

  /**
   *
   *
   * @private
   * @param {*} err
   * @memberof UploadHelperService
   */
  private fileDeletionError(err: any) {
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('Error on fbstorage_deleteFile: ', err);
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  }

  /**
   *
   *
   * @param {*} clientname
   * @return {*}
   * @memberof UploadHelperService
   */
  buildDicomStoresUrl(clientname: any): any {
    return `${environment.config.healthcareApi}/projects/${environment.config.gapi.projectname}/locations/${environment.config.gapi.location}/datasets/${clientname}/dicomStores`;
  }

  /**
   *
   *
   * @private
   * @param {UploadBlock} uploadBlock
   * @memberof UploadHelperService
   */
  private singleDICOMImportFinished(uploadBlock: UploadBlock) {
    // Set notification.
    this.notifications_$.DICOMStudyAdded((<UploadBlock>uploadBlock).DICOMMetaData, uploadBlock.id);

    //  params.uploadBlock.setStatus('completed');
    this.uiMessaging_$.toastMessage('DISK Upload completed', 'STATUS');
  }

  /**
   *
   *
   * @param {File[]} files
   * @return {*}
   * @memberof UploadHelperService
   */
  private getDetectedFileMessageData(): any {
    return {
      title: 'Malicious File Detected',
      message: 'Malicious file detected, removing the file is on going.',
      buttons: [{ text: 'OK' }],
    };
  }

  /**
   *
   *
   * @private
   * @param {UploadBlock} uploadBlock
   * @memberof UploadHelperService
   */
  private DICOMImportFinished(uploadBlock: UploadBlock) {
    // Set notification
    this.notifications_$.DICOMStudyAdded(uploadBlock.DICOMMetaData, uploadBlock.id);

    uploadBlock.setStatus(status[1]);
    this.uiMessaging_$.toastMessage('DICOM Import completed', 'STATUS');
  }

  /**
   *
   *
   * @private
   * @param {*} fileData
   * @param {Params} params
   * @param {*} file
   * @param {string} notificationType
   * @memberof UploadHelperService
   */
  // NOTE: Compare with PROD-download-disc branch.
  private createNotifications(fileData: any, params: Params, file: any, notificationType: string) {
    const notificationData = this.getNotificationData(fileData, params, file, notificationType);
    const currentUser = this.getCurrentUser();
    const createdNotification = this.notifications_$.createNotification(notificationData, currentUser);
    console.log('createdNotification :', createdNotification);
  }

  /**
   *
   *
   * @private
   * @param {*} fileData
   * @param {*} params
   * @param {*} file
   * @param {*} notificationType
   * @return {*}
   * @memberof UploadHelperService
   */
  private getNotificationData(fileData: any, params: any, file: any, notificationType: any): NotificationData {
    // this.notifications_$.fileAdded(params, file);
    const notificationData: NotificationData = {
      userId: this.auth_$.userData.getValue()['uid'],
      type: notificationType,
      fileId: fileData.fileId,
      fileType: params.type === 1 ? 'file' : 'dicom',
      folderId: file.parentFolderId || file.entryMeta.targetFolderId,
      caseId: params.uploadBlock.casename,
      userRole: this.auth_$.userData.getValue()['role'],
    };

    return notificationData;
  }

  /**
   *
   *
   * @private
   * @return {*}
   * @memberof UploadHelperService
   */
  private getCurrentUser(): { id: string; email: string; role: string; uid: string } {
    const { email, role, uid } = <{ email: string; role: string; uid: string }>this.auth_$.userData.getValue();
    return {
      id: this.auth_$.currentUser.getValue().uid,
      email: email,
      role: role,
      uid: uid,
    };
  }

  private getEntry(name, entryMeta, file) {
    return { name, desc: entryMeta.desc || '', fdate: entryMeta.fdate || file.fdate || '' };
  }

  private getDicomDir(entry, entryMeta, file) {
    return <Dicomdir>{
      entry: entry,
      entryMeta: entryMeta,
      uploadedDate: new Date().toString(),
      lastModified: file.lastModified,
      parentFolderName: file.parentFolderName,
      parentFolder: file.parentFolder,
    };
  }

  private handleCreateDataStoreV2(params): Promise<number> {
    return this.gAPI_$.createDataStore(
      params.storename,
      params.uploadBlock.casename,
      this.gAPI_$.gcloud_projectname,
      this.gAPI_$.gcloud_projectlocation,
    );
  }

  private getEntryMeta(entryMeta, file) {
    return {
      ...entryMeta,
      fdate: entryMeta.fdate || file.fdate || '',
      targetFolderId: entryMeta.targetFolderId || '',
    };
  }

  private handleCreateDataSet(clientname: string) {
    return this.createDataSet(clientname)
      .then(r => console.log('The dataset has been created:', r))
      .catch(err => console.error('Error creations the dataset', err));
  }

  private async handleDataStoreCreation(params) {
    const dataStoresByClient = await this.getDataStoresByClient(params.uploadBlock.casename);
    const matchDataStoresByClient = this.matchDataStoresByClient(
      params.storename,
      !dataStoresByClient ? [] : dataStoresByClient,
    );

    const conditions =
      (!dataStoresByClient.length || !matchDataStoresByClient) && !(await this.handleCreateDataStoreV2(params));

    if (conditions) {
      this.uiMessaging_$.toastMessage('The Data Store could not be created.', 'ERROR');
      return;
    }
  }

  private getUploadBlockFiles({ blockFiles }) {
    return blockFiles.filter(({ toUpload }) => toUpload !== false);
  }

  private getUploadBlockFireStoreFiles({ fireStoreFiles }) {
    return fireStoreFiles.filter(({ name }) => name !== dicomdirFileName);
  }

  private buildCreateDataSetUrl(datasetname: string) {
    const { healthcareApi, gapi } = environment.config;
    return (
      `${healthcareApi}` +
      `/projects/${gapi.projectname}` +
      `/locations/${gapi.location}` +
      `/datasets/${datasetname}` +
      `/create`
    );
  }

  /**
   *
   * @param datasetname
   * @returns
   */
  createDataSet(datasetname: string): Promise<boolean> {
    console.log('*********************************');
    console.log('createDataSet');
    console.log('*********************************');

    return this.http
      .get(this.buildCreateDataSetUrl(datasetname))
      .toPromise()
      .then(
        data => {
          console.log('The dataset has been created:', data);
          return true;
        },
        reason => {
          console.log(`Some error during dataset creation: ${datasetname}`, reason);
          return false;
        },
      )
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  private handleCheckResult(healthCareObject: any) {
    // Test HealthcareObject.
    if (healthCareObject.files.length === 0) {
      console.log('No files to upload');
      return false;
    } else {
      console.log('healthCareObject :', healthCareObject);
      return true;
    }
  }

  private generateUploadBlock2(uploadBlock: any) {
    return { uploadBlock, storename: this.storename, storeComplementname: this.up_storecomplementname };
  }

  private storeTheFile(file, params) {
    return this.firebase_$
      .fileStorageAdd(file, params.filepath)
      .catch(error => console.log('fileStorageAdd error:', error));
  }
}
