import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailerService {
  dialogConfig: MatDialogConfig;
  constructor(private http: HttpClient) {}

  notifyAdminByEmail(viewerurl: string, datasetId: string, dicomStoreId: string, studyId: string, useremail: string) {
    const sendEmailUrl = `https://us-central1-nuagedx-dev.cloudfunctions.net/email-sendEmail`;
    // const sendEmailUrl = `http://127.0.0.1:5001/nuagedx-dev/us-central1/email-sendEmail`;

    const htmlBody = `<p>Study ${studyId} has been deleted.</p>
      <p>Study URL: ${viewerurl}</p>
      <p>Dataset ID: ${datasetId}</p>
      <p>DICOM Store ID: ${dicomStoreId}</p>
      <p>User: ${useremail}</p>`;

    this.http
      .post(sendEmailUrl, { dest: 'arengifo@nuagedx.com', subject: 'Study Deletion attempt', body: htmlBody })
      .toPromise();
  }

  sendEmail(dest: string, subject: string, body: string) {
    const sendEmailUrl = `https://us-central1-nuagedx-dev.cloudfunctions.net/email-sendEmail`;
    return this.http.post(sendEmailUrl, { dest, subject, body }).toPromise();
  }

  sendOperationStatusEmail(messageData, action, status, email) {
    console.log('sendOperationStatusEmail');
    const url = `${environment.constants.cloudfunctionsURL}email-simpleEmail`;
    const dest = [email].join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body = action + ': ' + status + '\n' + messageData;
    console.log('body :', body);
    const subject = `NuagedX has uploaded a disc.`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) console.log('Email sent');
      },
      complete: () => {
        console.log('Completed');
      },
      error: err => {
        console.error(err);
      },
    });
  }
}
