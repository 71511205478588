export enum CustomActions {
  openClioMatter = 'open_clio_matter',
  openClioMatterFromDocument = 'open_clio_matter_from_document',
  goToNuageDx = 'go_to_nuagedx',
  openClioDocument = 'open_clio_document',
  viewMedicalImages = 'view_medical_images',
  uploadDicomDisk = 'upload_dicom_disk',
  meetingsScheduling = 'meetings_scheduling',
  shareDisc = 'share_disc',
}
