<div fxLayout="row">
  <div fxFlex>
    <h2 *ngIf="!title && close"
        [ngClass]="'mat-headline'">
      {{ roleSelected === UserRoles.admin ? 'Admin' : roleSelected }} List
    </h2>
    <h1 *ngIf="title && close"
        [ngClass]="'mat-h1'">
      {{ title }}
    </h1>
    <span *ngIf="subtitle && close"
          [ngClass]="'mat-small'">
      {{ subtitle }}
    </span>
  </div>
  <div fxFlex="none"
       fxFlexOffset="1">
    <button class="close-button"
            color="warn"
            type="button"
            mat-dialog-close
            mat-flat-button>
      X
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div *ngIf="search"
       fxLayout="column"
       fxLayoutAlign="center center">
    <div [ngClass]="
        'mdc-text-field mdc-text-field--outlined mdc-text-field--no-label mdc-text-field--with-leading-icon div-container'
      ">
      <mat-form-field color="warn"
                      appearance="fill"
                      [ngStyle]="{ width: '100%' }">
        <mat-label>
          Search {{ roleSelected | titlecase }}
        </mat-label>
        <input #searchClientsInput
               #searchCriteriaInput

               color="warn"
               placeholder="Search {{ roleSelected | lowercase }} by name"

               aria-label="Label"
               matInput
               [value]="searchCriteria"

               (input)="searchCriteria = searchCriteriaInput.value"
               (keyup)="filterUsers($event)">
      </mat-form-field>
    </div>
    <div class="div-container-2"
         fxLayout="row"
         fxLayoutAlign="center center">
      <button id="cancelSearch"
              [ngClass]="'mdc-button submission-button'"
              (click)="cancelSearch()">
        <span [ngClass]="'mdc-button__label'">
          Cancel
        </span>
      </button>
    </div>
  </div>
  <ul *ngIf="allUsers.length > 0"
      id="ulStyle"
      [ngClass]="'mdc-list mdc-list--two-line'">
    <div *ngFor="let user of allUsers"
         [ngClass]="'list-item'">
      <li tabindex="0"
          [ngClass]="'mdc-list-item'"
          (click)="openUser(user)">
        <div fxLayout="row"
             fxLayoutAlign="start center">
          <mat-icon class="icon-item"
                    aria-hidden="false"
                    aria-label="user icon"
                    fxFlex="nogrow">
            account_circle
          </mat-icon>
          <span fxFlex
                [ngClass]="'mdc-list-item__text'">
            <span [ngClass]="'mdc-list-item__primary-text'">
              <div [ngClass]="'mat-line'">
                {{user.name}}
              </div>
              <div [ngClass]="'mat-line mat-small'">
                {{user.email}}
              </div>
            </span>
          </span>
          <mat-icon aria-hidden="false"
                    aria-label="info icon"
                    fxFlex="nogrow">
            info
          </mat-icon>
        </div>
      </li>
    </div>
  </ul>
  <div id="fileDiag"
       aria-describedby="my-dialog-content"
       aria-labelledby="my-dialog-title"
       aria-modal="true"
       role="alertdialog"
       [ngClass]="'mdc-dialog userdiag'">
    <div [ngClass]="'mdc-dialog__container'">
      <div [ngClass]="'mdc-dialog__surface div-container-3'">
        <div *ngIf="currentUser"
             fxLayout="row"
             fxLayoutAlign="space-between">
          <div>
            <h4 class="mat-title">
              {{currentUser.name}}
            </h4>
          </div>
          <button color="warn"
                  mat-flat-button
                  (click)="closeModal()">
            X
          </button>
        </div>
        <div *ngIf="currentUser"
             style="margin: 10px">
          <ul [ngClass]="'mdc-list'">
            <li tabindex="0"
                [ngClass]="'mdc-list-item'">
              <span [ngClass]="'mdc-list-item__text'">
                Email - {{ currentUser.email }}
              </span>
            </li>
            <li [ngClass]="'mdc-list-item'">
              <span [ngClass]="'mdc-list-item__text'">
                Role - {{ currentUser.role }}
              </span>
            </li>
          </ul>
          <div *ngIf="userPermissions && deleteadmin"
               style="text-align: center">
            <button color="warn"
                    mat-flat-button
                    (click)="openDeleteConfirmDialog()">
              Remove {{ data.role | uppercase }}
            </button>
            <br>
          </div>
          <div *ngIf="currentUser.role === UserRoles.consultant">
            <mat-divider></mat-divider>
            <p *ngIf="!currentUser.filesSharedWith.length"
               style="text-align: center">
              This user has no shared files yet.
            </p>
            <div *ngIf="currentUser.filesSharedWith.length"
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 fxLayoutGap="gappx">
              <h4 id="profileFont">
                This user has shared files of these clients:
              </h4>
              <ul *ngIf="currentUser.filesSharedWith"
                  [ngClass]="'mdc-list'">
                <li *ngFor="let sharedFile of currentUser.filesSharedWith"
                    tabindex="0"
                    [ngClass]="'mdc-list-item'">
                  <span [ngClass]="'mdc-list-item__text'">
                    {{ sharedFile.patientCaseName }} -
                    <span class="title-container">
                      {{ sharedFile.patientName | titlecase }}
                    </span>
                  </span>
                </li>
              </ul>
              <h4 *ngIf="!currentUser.filesSharedWith"
                  id="profileFont">
                No files shared with this {{ data.role }}
              </h4>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column"></div>
        </div>
      </div>
    </div>
    <div [ngClass]="'mdc-dialog__scrim'"></div>
  </div>
  <div id="fileDiag"
       aria-describedby="my-dialog-content"
       aria-labelledby="my-dialog-title"
       aria-modal="true"
       role="alertdialog"
       [ngClass]="'mdc-dialog deleteconfirm'">
    <div [ngClass]="'mdc-dialog__container'">
      <div [ngClass]="'mdc-dialog__surface'">
        <div class="div-container-4">
          <h2 id="my-dialog-title"
              [ngClass]="'mdc-dialog__title'">
            Confirm Remove {{ data.role }}
          </h2>
        </div>
        <div fxLayoutAlign="center center">
          <h4 *ngIf="currentUser"
              id="profileFont">
            Are you sure you want to delete {{ currentUser.name }}?
          </h4>
        </div>
        <footer [ngClass]="'mdc-dialog__actions'">
          <button color="warn"
                  type="button"
                  data-mdc-dialog-action="accept"
                  data-mdc-dialog-button-default
                  mat-flat-button
                  (click)="markDeleted(currentUser.uid)">
            <span [ngClass]="'mdc-button__label'">
              Yes
            </span>
          </button>
          &nbsp; &nbsp;
          <button color="warn"
                  data-mdc-dialog-action="no"
                  mat-flat-button
                  (click)="closeModal()">
            <span [ngClass]="'mdc-button__label'">
              No
            </span>
          </button>
        </footer>
      </div>
    </div>
    <div [ngClass]="'mdc-dialog__scrim'"></div>
  </div>
</div>
