<div fxLayout="row"
     fxLayoutAlign="start center"
     [ngClass]="'mat-body'">
  <div *ngIf="currentFolder === ''"
       class="breadcrumb-home-button no-link">
    <mat-icon>
      home
    </mat-icon>
    <span>
      &nbsp;Home
    </span>
  </div>

  <button *ngIf="currentFolder !== ''"
          class="breadcrumb-home-button"
          aria-label="Back to home"
          mat-button
          (click)="handleGo('')">
    <mat-icon>
      home
    </mat-icon>
    &nbsp;
    <span class="mat-body">
      Home
    </span>
  </button>

  <mat-icon *ngIf="currentFolder !== ''">
    keyboard_arrow_right
  </mat-icon>

  <span *ngIf="currentFolderName !== ''"
        class="folder-name"
        [ngStyle]="getButtonStyle(currentFolderName)">
    {{ currentFolderLabel }}
  </span>
</div>
