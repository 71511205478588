<div fxLayout="row">
  <div fxFlex="5"
       fxLayout="column">
    <button *ngIf="isRestrictedProfile(consultant); else notRestrictedProfile"
            mat-icon-button
            matTooltip="Click to toggle Visibility profile"
            (click)="makeProfilePublic($event, consultant)">
      <mat-icon color="warn">
        toggle_off
      </mat-icon>
    </button>
    <ng-template #notRestrictedProfile>
      <button mat-icon-button
              matTooltip="Click to toggle Visibility profile"
              (click)="makeProfileRestricted($event, consultant)">
        <mat-icon>
          toggle_on
        </mat-icon>
      </button>
    </ng-template>
  </div>

  <div fxFlex
       fxFlexOffset="5"
       fxLayout="column">
    <div [ngClass]="'mat-line small-font'">
      <b [ngStyle]="{ color: consultant.clientMatter === caseName ? 'red' : '' }">
        {{consultant.name}}
      </b>
      <mat-icon *ngIf="consultant.clientMatter === caseName"
                class="person-icon"
                color="warn"
                matTooltip="Client Profile Account user">
        person
      </mat-icon>
      <span *ngIf="consultant.clientMatter === caseName"
            class="client-user-label">
        (Client user)
      </span>
      <mat-icon *ngIf="consultant.highlighted"

                color="warn"
                style="margin-left: 5px; vertical-align: middle; display: inline-block"

                aria-hidden="false"
                aria-label="The file is shared with this consultant."
                matTooltip="The file is shared with this consultant."
                [ngClass]="'file-icon'">
        people
      </mat-icon>
    </div>

    <div [ngClass]="'mat-line small-font'">
      {{ consultant.email }}
    </div>

    <div [ngClass]="'mat-line small-font'">
      <span>
        {{
          isRestrictedProfile(consultant)
            ? 'This user can not see file comments and attachments.'
            : 'This user can see file comments and attachments.'
        }}
      </span>
    </div>

    <div style="margin-top: 10px"
         fxFlex
         fxLayout="row"
         fxLayoutGap="10px">
      <button *ngIf="selected && checkSelected(consultant)"
              color="warn"

              mat-flat-button
              matTooltip="Stop sharing selected files with this consultant."

              [ngClass]="'small-font'"
              (click)="$event.stopPropagation(); unShare(consultant)">
        <mat-icon>
          block
        </mat-icon>
        Unshare selected
      </button>

      <button *ngIf="selected && selectedFiles.length > 1 && !checkSelected(consultant)"
              color="warn"

              mat-flat-button
              matTooltip="Share selected files with this consultant."

              [ngClass]="'small-font'"
              (click)="$event.stopPropagation(); share(consultant)">
        <mat-icon>
          share
        </mat-icon>
        Share selected
      </button>

      <button *ngIf="selected"
              color="warn"

              mat-flat-button
              matTooltip="Unshare all with this consultant."

              [ngClass]="'small-font'"
              (click)="$event.stopPropagation(); unshareAllWith(consultant)">
        <mat-icon>
          block
        </mat-icon>
        Unshare all shared
      </button>
    </div>

    <div *ngIf="sharedFilesCount === 0 && selected"
         class="note">
      This user has no shared files in this folder. To see the files shared with this consultant go to the
      <button class="red-background small-font"
              (click)="goToIndex($event)">
        INDEX
      </button>
      folder.
    </div>
  </div>
</div>
