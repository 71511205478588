<div [ngClass]="'available-consultants-container'">
  <mat-selection-list #consultants
                      [multiple]="false">
    <mat-list-option *ngFor="let consultant of availableConsultantsList"
                     #option
                     class="available-consultants-list-option"
                     [ngClass]="{ 'mat-small': true, 'client-upload-user-row': consultant.clientMatter === caseName }"
                     [value]="consultant"
                     (click)="handleOptionClick(consultant)">
      <div fxLayout="row">
        <div fxFlex
             fxLayout="column">
          <div [ngClass]="'mat-line small-font'">
            <b>
              {{ consultant.name }}
            </b>
            <mat-icon *ngIf="consultant.clientMatter === caseName"
                      color="warn"
                      style="vertical-align: middle; display: inline-block"
                      matTooltip="Client Profile Account user">
              person
            </mat-icon>
            <span *ngIf="consultant.clientMatter === caseName"
                  class="client-user-label">
              (Client user)
            </span>
          </div>
          <div [ngClass]="'mat-line small-font'">
            {{ consultant.email }}
          </div>
          <div *ngIf="
              consultant.shared === true ||
              (consultant.shared === false && option.selected && availableConsultantsList.length > 0)
            "
               fxFlex="nogrow"
               fxFlexOffset="4"
               fxLayout="column">
            <button *ngIf="consultant.shared === true"

                    mat-flat-button
                    matTooltip="Stop sharing selected files with this consultant."

                    [ngClass]="'small-font'"
                    (click)="$event.stopPropagation(); unShare(consultant)">
              Unshare selected
            </button>

            <button *ngIf="consultant.shared === false && option.selected && availableConsultantsList.length > 0"
                    color="warn"

                    mat-flat-button
                    matTooltip="Share selected files with this consultant."

                    [ngClass]="'small-font'"
                    (click)="share(consultant, $event)">
              <mat-icon>
                share
              </mat-icon>
              Share selected
            </button>
          </div>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>
  <!-- <p
    *ngIf="!availableConsultantsList.length"
    [ngClass]="'small-font margin-left-20'">
    There are <b>no available consultants</b> for this client. Go an
    <a
      href="#"
      (click)="createConsultant($event)"
      >create a consultant</a
    >.
  </p> -->
</div>
