<app-window-title title="Add a contact" [closable]=true></app-window-title>
<p> Please add a name for this custom guest </p>
<form #form="ngForm" (ngSubmit)="handleSubmit(name.value,jobDescription.value)">
  <mat-form-field color="warn" appearance="fill" [ngStyle]="{ width: '100%' }">
    <mat-label>Name</mat-label>
    <input #name matInput required />
  </mat-form-field>
  <mat-form-field color="warn" appearance="fill" [ngStyle]="{ width: '100%' }">
    <mat-label>Job Description</mat-label>
    <input #jobDescription matInput required />
  </mat-form-field>
  <mat-form-field color="warn" appearance="fill" [ngStyle]="{ width: '100%' }">
    <mat-label>Email</mat-label>
    <input disabled #email matInput [value]="data.email" />
  </mat-form-field>
  <button mat-flat-button color="warn" type="submit">OK</button>
</form>
